
import Vue from "vue";
import TextFieldBox from "../field-box/TextFieldBox.vue";
import { storage } from "../../../../shared/plugins/firebase-app";

export default Vue.extend({
  inheritAttrs: false,
  components: { TextFieldBox },
  props: {
    value: {
      type: Object,
      required: false,
    },
    exitEditModeOnEnter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
      item: this.value,
      formFiles: () => {
        return this.value.options.fileLink
          ? [
              {
                source: this.value.options.fileLink,
                options: {
                  type: "local",
                },
              },
            ]
          : [];
      },
      server: () => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const $this: any = this;
        return {
          process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            var storageRef = storage.ref(`${$this.item.id}.${$this.getFileExtension(file.name)}`);
            const uploadTask = storageRef.put(file);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
              },
              (e) => {
                console.error(e);
                error(e.message);
              },
              async () => {
                // Handle successful uploads on complete
                try {
                  const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                  $this.item.options.fileLink = downloadURL;
                  $this.handleChange();
                } catch (e: any) {
                  console.error(e);
                  error(e.message);
                }
              }
            );
            return {
              abort: () => {
                uploadTask.cancel();
                abort();
              },
            };
          },
          async remove(file, success, error) {
            try {
              var storageRef = storage.ref($this.getFileNameFromUrl(file));
              await storageRef.delete();
              $this.item.options.fileLink = null;
              $this.handleChange();
            } catch (e) {
              error(e);
              return;
            }
            success();
          },
        };
      },
    };
  },
  mounted() {
    if (!this.item.options) {
      this.item.options = {
        fileLink: null,
        LinkTitle: "Download",
      };
      this.$forceUpdate();
    }
  },
  methods: {
    getFileNameFromUrl(url = "") {
      const a = url.indexOf(this.item.id);
      let b = url.indexOf("?");
      if (b < 0) b = url.length;
      const fileName = url.substring(a, b);
      return fileName;
    },
    getFileExtension(fileName) {
      const i = fileName.lastIndexOf(".");
      if (i >= 0) {
        return fileName.substring(i, fileName.length);
      }
      return "";
    },
    handleChange() {
      setTimeout(() => {
        this.$emit("input", this.item);
      }, 100);
    },
  },
});
