
import Vue from "vue";
import store from "@/store";
import TextFieldBox from "../field-box/TextFieldBox.vue";
import AutoCompleteBox from "../field-box/AutoCompleteBox.vue";
import formService from "@/services/formService";
import { Communication, Company, Form } from "../../../../shared/models";
import { SetCommunicationScheduleRequestModel } from "../../../../shared/models/requests/SetCommunicationScheduleRequestModel";
import { FindPastSessionsRequestModel } from "../../../../shared/models/requests/FindPastSessionsRequestModel";
import axios from "@/plugins/axios";
import { outputEndpointBaseURL } from "../../../../shared/constants";

export default Vue.extend({
  components: { TextFieldBox, AutoCompleteBox },
  props: {
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    communication: {
      type: Object as () => Communication,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      conditions: [
        { id: "no-condition", title: "No Condition" },
        { id: "not-seen", title: "Previous communication not seen" },
        { id: "seen-not-clicked", title: "Previous communication seen and not clicked" },
        { id: "seen-clicked", title: "Previous communication seen and clicked" },
        { id: "form-abandoned", title: "Form Abandoned" },
      ],
      loadingChildren: false,
      loadingLinkableElements: false,
      links: [] as any[],
      linkableElements: [] as any[],
      formIsValid: false,
      pastSessions: [] as any[],
      selectedPastSessions: [] as any[],
      showPastSessionsPopup: false,
      loadingPastSessions: false,
      loadingPastSessionsProcess: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        depth: (val) => (val >= 1 && val <= 5) || `Depth must be between 1 and 5`,
        zeroOrPositive: (val) => val >= 0 || `Invalid value`,
      },
    };
  },
  created() {
    this.loadFormChildrenAndLinks();
    this.loadLinkableElements();
  },
  watch: {
    // "communication.formIdToSend"() {
    //   this.computedCommunication.datasource = null;
    //   this.loadLinkableElements();
    // },
    communication: {
      handler(newValue, oldValue) {
        this.handleChange();
      },
      deep: true,
    },
  },
  methods: {
    getPastSessionLink(id: string) {
      return `${outputEndpointBaseURL.ensureNotEndsWith("/")}/session/${id}`;
    },
    async loadPastSessions() {
      this.loadingPastSessions = true;
      this.loadingPastSessionsProcess = true;
      const requestBody: FindPastSessionsRequestModel = {
        communicationId: this.communication.id,
        companyId: this.company.id,
        formId: this.form.id,
      };
      const response = await axios.post("/FindPastSessions", requestBody);
      this.pastSessions = response.data.responses;
      this.loadingPastSessions = false;
      this.loadingPastSessionsProcess = false;
    },
    async applyToPastSessions() {
      this.loadingPastSessionsProcess = true;
      const requestBody: SetCommunicationScheduleRequestModel = {
        communicationId: this.communication.id,
        companyId: this.company.id,
        sessionIds: this.selectedPastSessions.map((item) => item.id),
      };
      await axios.post("/SetCommunicationSchedule", requestBody);
      this.loadingPastSessionsProcess = false;
      this.showPastSessionsPopup = false;
      this.selectedPastSessions = [];
    },
    closePastSessionDialog() {
      this.pastSessions = [];
      this.selectedPastSessions = [];
      this.showPastSessionsPopup = false;
    },
    async showPastSessions() {
      this.showPastSessionsPopup = true;
      await this.loadPastSessions();
    },
    async loadFormChildrenAndLinks() {
      try {
        this.loadingChildren = true;
        const children = await formService.getFormChildrenAndLinks(this.form, this.companyId);
        this.links = children;
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loadingChildren = false;
      }
    },
    deleteCommunication() {
      this.$emit("delete", this.communication);
    },
    handleChange() {
      this.$emit("change", this.communication);
      (this.$refs.form as any).validate();
    },
    async loadLinkableElements() {
      try {
        this.linkableElements = [];
        if (!this.communication || !this.communication.formId) {
          return;
        }
        this.loadingLinkableElements = true;
        const linkableElements = await formService.getLinkableElements(this.companyId, this.communication.formId);
        let lastGroup;
        linkableElements.forEach((x) => {
          if (x.isGroup) {
            lastGroup = x;
            this.linkableElements.push({ header: x.value });
          } else {
            this.linkableElements.push({ ...x, group: lastGroup.value });
          }
        });
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.loadingLinkableElements = false;
      }
    },
  },
  computed: {
    company(): Company {
      return store.getters.company;
    },
    companyId(): string {
      return store.getters.companyId;
    },
    computedCommunication(): Communication {
      return this.communication;
    },
    computedPastSessionsHeaders(): any {
      return [
        { text: "Id", value: "id" },
        { text: "Date", value: "datetime" },
        { text: "Clicker Email Address", value: "clickerEmailAddress" },
        { text: "Recipient Email Address", value: "recipientEmailAddress" },
      ];
    },
  },
});
