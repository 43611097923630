import { Theme } from "../models/Theme";
import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";

export default class ThemeService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public async getTheme(themeId: string) {
    if (!themeId) {
      return null;
    }
    return await this.db.collection("Themes").doc(themeId).get();
  }

  public async getAllThemes(companyId: string) {
    const defaultAndUserTheme = await this.getCompanyThemesWithPublicThemes(companyId);
    const themes: Theme[] = [];
    defaultAndUserTheme.forEach((docSnapshot) => {
      themes.push(docSnapshot.data() as Theme);
    });
    return themes;
  }

  public async saveTheme(theme: Partial<Theme>) {
    return await this.db
      .collection("Themes")
      .doc(theme.id as string)
      .set(theme, { merge: true });
  }

  public async deleteTheme(id: string) {
    return await this.db.collection("Themes").doc(id).delete();
  }

  public async getCompanyThemesWithPublicThemes(companyId: string) {
    const q1 = this.db.collection("Themes").where("companyId", "==", null).get();
    const q2 = this.db.collection("Themes").where("companyId", "==", companyId).get();

    const [querySnapshot1, querySnapshot2] = await Promise.all([
      q1 as Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>,
      q2 as Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>,
    ]);

    const usersArray1 = querySnapshot1.docs;
    const usersArray2 = querySnapshot2.docs;

    return usersArray1.concat(usersArray2);
  }
}
