
import Vue from "vue";
import FormView from "@/components/form/FormView.vue";
import PublishesView from "@/components/form/PublishesView.vue";
import DesignView from "@/components/form/DesignView.vue";
import CommunicationView from "@/components/form/CommunicationsView.vue";
import formService from "@/services/formService";
import store from "@/store";
import { debounce } from "../../../../shared/extensions/tlence";
import { Contact, ContactGroup, Form, Theme } from "../../../../shared/models";
import CommunicationsLogView from "@/components/form/CommunicationsLogView.vue";
import FormSessionsView from "@/components/form/FormSessionsView.vue";
import navigateIcon from "@/assets/navigate-icon.png";

export default Vue.extend({
  components: { FormView, PublishesView, DesignView, CommunicationView, CommunicationsLogView, FormSessionsView },
  props: {
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
    forms: {
      type: Array as () => Form[],
      required: true,
      default: () => [],
    },
    contactGroups: {
      type: Array as () => ContactGroup[],
      required: true,
      default: () => [],
    },
    contacts: {
      type: Array as () => Contact[],
      required: true,
      default: () => [],
    },
    themes: {
      type: Array as () => Theme[],
      required: true,
      default: () => [],
    },
    tabSelected: {
      type: Number,
      required: false,
      default: () => null,
    },
    showNavigate: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      navigateIcon,
      saving: false,
      unsubscribe: null as any,
      selectedTab: this.tabSelected != null ? this.tabSelected : parseInt((this.$route.query.tab || "0").toString()) || 0,
    };
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  async created() {
    if (this.$route.query.tab != this.selectedTab.toString()) {
      await this.$router.replace({ query: { ...this.$route.query, tab: this.selectedTab.toString() } });
    }
  },
  methods: {
    newLinkAdded(event) {
      this.$emit("change", event);
    },
    async saveForm(formData: Form, successCallBack?, errorCallBack?) {
      try {
        this.saving = true;
        await formService.saveForm(formData);
        this.$emit("formUpdated", formData);
        if (successCallBack) {
          successCallBack();
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
        if (errorCallBack) {
          errorCallBack(error);
        }
      } finally {
        this.saving = false;
      }
    },
    navigatorClick() {
      this.$emit("navigatorClicked", true);
    },
    sessionSeenChanged(Form: Form) {
      this.$emit("sessionSeenChanged", Form);
    },
    async tabbed() {
      if (this.$route.query.tab != this.selectedTab.toString()) {
        await this.$router.replace({ query: { ...this.$route.query, tab: this.selectedTab.toString() } });
      }
    },
    async handleChildrenOrderChange(children: Form[], callback) {
      try {
        this.saving = true;
        const x = [] as Promise<any>[];
        children.forEach(async (form, index) => {
          x.push(formService.setFormOrder(form, index));
        });
        await Promise.all(x);
        if (callback) {
          callback();
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.saving = false;
      }
    },
    formAdded(child: Form) {
      this.$emit("formAdded", child);
    },
    linkAdded(link: any) {
      this.$emit("linkAdded", link);
    },
    openChildForm(child: any) {
      this.$emit("openChildForm", child);
    },
  },
  watch: {
    queryTabValue(newValue, oldValue) {
      if (newValue !== this.selectedTab) {
        this.selectedTab = newValue;
      }
    },
    saving(newValue) {
      (window as any).onbeforeunload = newValue
        ? function () {
            return "You have unsaved changes!";
          }
        : undefined;
    },
    tabSelected(newValue) {
      this.selectedTab = newValue;
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    userId(): string {
      return store.getters.userId;
    },
    debouncedSaveForm(): (...args: any[]) => void {
      return debounce(this.saveForm, 500);
    },
    computedForm(): any {
      return this.form;
    },
    queryTabValue(): number {
      return parseInt((this.$route.query.tab || "0").toString()) || 0;
    },
  },
});
