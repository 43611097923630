
import Vue from "vue";
import TextFieldBox from "../field-box/TextFieldBox.vue";

export default Vue.extend({
  inheritAttrs: false,
  components: { TextFieldBox },
  props: {
    value: {
      type: Object,
      required: false,
    },
    exitEditModeOnEnter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
      item: this.value,
      errorMessage: "",
      errorItem: "",
    };
  },
  mounted() {
    if (!this.item.options) {
      this.item.options = {
        items: [{ value: "", text: "" }],
        multiple: false,
        required: false,
      };
      this.$forceUpdate();
    }
  },
  methods: {
    removeItem(index) {
      this.item.options.items.splice(index, 1);
      this.$forceUpdate();
      this.handleChange(index);
    },
    AddNewItem() {
      this.item.options.items.push({ value: "", text: "Type Option" });
      this.$forceUpdate();
      this.handleChange(null);
    },
    handleChange(index = null) {
      setTimeout(() => {
        if (index == null) {
          this.$emit("input", this.item);
        } else {
          this.errorMessage = "";
          this.errorItem = "";
          const changedItem = this.item.options.items[index];
          if (changedItem) {
            const duplicateOptions = this.item.options.items.filter((i) => changedItem.text == i.text);
            if (duplicateOptions.length > 1) {
              this.errorMessage = "Duplicate option is invalid";
              this.errorItem = changedItem.text;
            }
            if (!this.errorMessage) this.$emit("input", this.item);
          }
        }
      }, 100);
    },
  },
});
