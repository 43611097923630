
import Vue from "vue";
import store from "@/store";
import { Communication, CommunicationSchedule, Form, Session, Setting, Token } from "../../../../shared/models";
import SessionData from "@/components/audience/SessionData.vue";
import { outputEndpointBaseURL } from "../../../../shared/constants";
import jwt from "jwt-simple";
import publishService from "@/services/publishService";
import QrcodeVue from "qrcode.vue";
import DateRangePicker from "@/components/field-box/DateRangePicker.vue";
import communicationService from "@/services/communicationService";
import axios from "@/plugins/axios";

export default Vue.extend({
  components: { SessionData, QrcodeVue, DateRangePicker },
  props: {
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      generatingUrl: false,
      loadingCommunications: false,
      dateRange: [] as string[],
      selectedSeenFilter: "All",
      seenFilteritems: ["All", "Read", "Unread"],
      showSharePopup: false,
      showCommunicationsPopup: false,
      shareTab: "directlink",
      linkUrl: "",
      selectedSession: null as Session | null,
      selectedCommunications: [] as Communication[],
      scheduling: false,
      refreshKey: 1,
    };
  },
  methods: {
    handleFormChange(form, successCallBack?, errorCallBack?) {
      this.$emit("change", form, successCallBack, errorCallBack);
    },
    async showSessionLink(item: Session) {
      this.selectedSession = item;
      this.showSharePopup = true;
      this.linkUrl = await this.getUrlLink(item);
    },
    sessionSeenChanged(form: Form) {
      this.$emit("sessionSeenChanged", form);
    },
    async showCommunications(item: Session) {
      try {
        this.selectedSession = item;
        this.showCommunicationsPopup = true;
        this.loadingCommunications = true;
        this.selectedCommunications = await communicationService.getFormCommunications(item.formId, item.companyId);
        for (const c of this.selectedCommunications) {
          const communicationSchedules: CommunicationSchedule[] = [];
          const docs = (await communicationService.getCommunicationScheduleBySession(item.id, c.id, c.companyId)).docs;
          for (const doc of docs) {
            const docData = doc.data() as CommunicationSchedule;
            docData.id = doc.id;
            communicationSchedules.push(docData);
            (c as any).communicationSchedules = communicationSchedules;
          }
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.loadingCommunications = false;
      }
    },
    async getUrlLink(item: Session): Promise<string> {
      try {
        this.generatingUrl = true;
        if (!item.linkId) {
          return "";
        }
        const selectedPublish = (await publishService.getById(item.linkId as string)).data();
        const payload: Token = {
          sessionId: item.id,
          linkId: item.linkId,
          formId: item.formId,
          companyId: item.companyId,
          theme: item.theme,
          contactIds: item.contactIds,
          contactGroupIds: item.contactGroupIds,
          clickerEmailAddress: item.clickerEmailAddress,
          parentSessionId: null,
        };
        const token = jwt.encode(payload, this.jwtClientSecret);
        const link = `${
          selectedPublish?.redirectURL ? selectedPublish.redirectURL.ensureNotEndsWith("/") : outputEndpointBaseURL.ensureNotEndsWith("/")
        }/?link=${token}`;
        return link;
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
        return "";
      } finally {
        this.generatingUrl = false;
      }
    },
    copyToClipboard() {
      const input = this.$refs.copytext as any;
      input.setAttribute("type", "text");
      input.focus();
      input.select();
      const isSuccessful = document.execCommand("copy");
      if (!isSuccessful) {
        console.error("Failed to copy text.");
      }
      input.setAttribute("type", "hidden");
    },
    async scheduleNow(communicationId: string): Promise<void> {
      try {
        if (this.selectedSession) {
          this.scheduling = true;
          await axios.put("/Visit", { communicationId, sessionId: this.selectedSession.id });
          await this.showCommunications(this.selectedSession);
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.scheduling = false;
      }
    },
  },
  watch: {},
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    computedForm(): any {
      return this.form;
    },
    jwtClientSecret() {
      return (store.getters.setting as Setting).jwtClientSecret;
    },
  },
});
