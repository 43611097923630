
import Vue from "vue";
import store from "@/store";
import PublishDetail from "@/components/form/PublishDetail.vue";
import IdGenerator from "../../../../shared/extensions/IdGenerator";
import { debounce } from "../../../../shared/extensions/tlence";
import { Publish, Form, Token, Contact, ContactGroup, Theme, Setting } from "../../../../shared/models";
import publishService from "@/services/publishService";
import { outputEndpointBaseURL } from "../../../../shared/constants";
import jwt from "jwt-simple";
import formService from "@/services/formService";
import QrcodeVue from "qrcode.vue";
import EmbeddedBox from "../EmbeddedBox.vue";

export default Vue.extend({
  components: { PublishDetail, QrcodeVue, EmbeddedBox },
  props: {
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
    forms: {
      type: Array as () => Form[],
      required: true,
      default: () => [],
    },
    contacts: {
      type: Array as () => Contact[],
      required: true,
      default: () => [],
    },
    contactGroups: {
      type: Array as () => ContactGroup[],
      required: true,
      default: () => [],
    },
    themes: {
      type: Array as () => Theme[],
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      publishes: [] as Publish[],
      selectedPublish: null as Publish | null,
      saving: false,
      loading: false,
      shareEmailSubject: "",
      showSharePopup: false,
      shareTab: "email",
      formEmbedTab: "embed",
      shareEmailOption: "enterAddress",
      shareEmailAddress: "",
      showEmbedFormPopup: false,
      sendingMail: false,
      emailFormValid: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        email: (value) => {
          const pattern =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid email.";
        },
      },
    };
  },
  async mounted() {
    await this.loadPublishes();
    if (this.publishes && this.publishes.length > 0) {
      let p = this.publishes[0];
      const queryLinkId = this.$route.query["linkId"];
      if (queryLinkId) {
        const doc = this.publishes.find((x) => x.id === queryLinkId.toString());
        if (doc) {
          p = doc;
        }
      }
      this.setSelectedPublish(p);
    }
  },
  methods: {
    setSelectedPublish(item: Publish | null) {
      this.selectedPublish = item;
    },
    async deletePublish(publish: Publish) {
      try {
        this.saving = true;
        const r = await this.$swal({
          title: "Delete Link",
          text: "Are you sure to delete this Link?",
          buttons: [
            {
              text: "No",
              value: false,
              visible: true,
              closeModal: true,
            },
            {
              text: "Yes",
              value: true,
              visible: true,
              closeModal: true,
            },
          ],
        });
        if (r === true) {
          await publishService.deletePublish(publish.id);
          await this.loadPublishes();
          if (this.publishes.length > 0) {
            this.setSelectedPublish(this.publishes[this.publishes.length - 1]);
          } else {
            this.setSelectedPublish(null);
          }
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.saving = false;
      }
    },
    async addNewPublish() {
      try {
        this.saving = true;
        const id = IdGenerator.newId();
        const publishData: Publish = {
          id: id,
          name: `Link ${this.publishes.length + 1}`,
          order: this.publishes.length + 1,
          disabled: false,
          disabledRedirect: null,
          avFrom: false,
          avFromDate: null,
          avFromRedirect: null,
          avUntil: false,
          avUntilDate: null,
          avUntilRedirect: null,
          linkAlias: false,
          linkAliasTag: null,
          contact: false,
          contactIds: [],
          contactGroup: false,
          contactGroupIds: [],
          depth: 1,
          selectedTheme: null,
          formId: this.form.id,
          companyId: this.companyId,
          redirectURL: "",
        };
        await publishService.savePublish(publishData);
        await this.loadPublishes();
        this.setSelectedPublish(this.publishes[this.publishes.length - 1]);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.saving = false;
      }
    },
    async savePublish(publish: Publish) {
      try {
        this.saving = true;
        await publishService.savePublish(publish);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.saving = false;
      }
    },
    async loadPublishes() {
      try {
        this.loading = true;
        this.publishes = await publishService.getFormPublishes(this.form.id, this.companyId);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    openPopup() {
      if (this.shareEmailSubject == "") {
        this.shareEmailSubject = this.form.name;
      }
      this.showSharePopup = true;
    },
    openEmbedFormPopup() {
      this.showEmbedFormPopup = true;
    },
    async sendMail() {
      try {
        this.sendingMail = true;
        const mailDetail = {
          mailOption: this.shareEmailOption,
          mailFrom: this.$store.getters.company.noReplyEmail,
          mailSubject: this.shareEmailSubject,
        };
        if (this.shareEmailOption == "contacts" && this.selectedPublish?.contact) {
          Vue.set(mailDetail, "mailTo", this.selectedPublish.contactIds);
        } else if (this.shareEmailOption == "enterAddress") {
          Vue.set(mailDetail, "mailTo", this.shareEmailAddress);
        } else if (this.shareEmailOption == "contactGroups" && this.selectedPublish?.contactGroup) {
          Vue.set(mailDetail, "mailTo", this.selectedPublish.contactGroupIds);
        }
        const response = await formService.sendFormViaMail(mailDetail, this.form.key, this.selectedPublish?.id as string);
        this.$notification.showSuccess(response.Message);
      } catch (err) {
        this.$notification.showError("Email has not been sent");
        console.error(err);
      } finally {
        this.sendingMail = false;
      }
    },
    copyToClipboard() {
      const input = this.$refs.copytext as any;
      input.setAttribute("type", "text");
      // 2) Select the text
      input.focus();
      input.select();
      // 3) Copy text to clipboard
      const isSuccessful = document.execCommand("copy");
      // 4) Catch errors
      if (!isSuccessful) {
        console.error("Failed to copy text.");
      }
      input.setAttribute("type", "hidden");
    },
  },
  computed: {
    getSelectedPublish(): string | null {
      return this.selectedPublish ? this.selectedPublish.id : null;
    },
    companyId(): string {
      return store.getters.companyId;
    },
    computedForm(): any {
      return this.form;
    },
    jwtClientSecret() {
      return (store.getters.setting as Setting).jwtClientSecret;
    },
    debouncedSavePublish(): (...args: any[]) => void {
      return debounce(this.savePublish, 500);
    },
    getUrlLink(): string {
      const payload: Token = {
        sessionId: null,
        linkId: this.selectedPublish ? this.selectedPublish.id : null,
        formId: this.form.id,
        companyId: this.companyId,
        theme: null,
        contactIds: [],
        contactGroupIds: [],
        clickerEmailAddress: null,
        parentSessionId: null,
      };
      const token = jwt.encode(payload, this.jwtClientSecret);
      const link = `${
        this.selectedPublish?.redirectURL
          ? this.selectedPublish.redirectURL.ensureNotEndsWith("/")
          : outputEndpointBaseURL.ensureNotEndsWith("/")
      }/?link=${token}`;
      return link;
    },
    allowShareOnWeb(): boolean {
      return (this.selectedPublish?.depth || 0) < 2;
    },
  },
});
