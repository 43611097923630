
import Vue from "vue";
import store from "@/store";
import CommunicationDetail from "@/components/form/CommunicationDetail.vue";
import IdGenerator from "../../../../shared/extensions/IdGenerator";
import { debounce } from "../../../../shared/extensions/tlence";
import { Communication, Form } from "../../../../shared/models";
import communicationService from "@/services/communicationService";

export default Vue.extend({
  components: { CommunicationDetail },
  props: {
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      communications: [] as Communication[],
      selectedCommunication: null as Communication | null,
      saving: false,
      loading: false,
    };
  },
  async mounted() {
    await this.loadCommunications();
    if (this.communications && this.communications.length > 0) {
      this.setSelectedCommunication(this.communications[0]);
    }
  },
  methods: {
    setSelectedCommunication(item: Communication | null) {
      this.selectedCommunication = item;
    },
    async deleteCommunication(communication: Communication) {
      try {
        this.saving = true;
        const r = await this.$swal({
          title: "Delete Communication",
          text: "Are you sure to delete this Communication?",
          buttons: [
            {
              text: "No",
              value: false,
              visible: true,
              closeModal: true,
            },
            {
              text: "Yes",
              value: true,
              visible: true,
              closeModal: true,
            },
          ],
        });
        if (r === true) {
          await communicationService.deleteCommunication(communication);
          await this.loadCommunications();
          if (this.communications.length > 0) {
            this.setSelectedCommunication(this.communications[this.communications.length - 1]);
          } else {
            this.setSelectedCommunication(null);
          }
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.saving = false;
      }
    },
    async addNewCommunication() {
      try {
        this.saving = true;
        const id = IdGenerator.newId();
        const communicationData: Communication = {
          id: id,
          formId: this.form.id,
          name: `Communication ${this.communications.length + 1}`,
          order: this.communications.length + 1,
          scheduled: {
            year: 0,
            month: 0,
            week: 0,
            day: 0,
            hour: 0,
          },
          formIdToSend: null,
          recipient: 1,
          datasource: null,
          depth: 1,
          condition: null,
          companyId: this.companyId,
        };
        await communicationService.saveCommunication(communicationData);
        await this.loadCommunications();
        this.setSelectedCommunication(this.communications[this.communications.length - 1]);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.saving = false;
      }
    },
    async saveCommunication(communication: Communication) {
      try {
        this.saving = true;
        await communicationService.saveCommunication(communication);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.saving = false;
      }
    },
    async loadCommunications() {
      try {
        this.loading = true;
        this.communications = await communicationService.getFormCommunications(this.form.id, this.companyId);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    computedForm(): any {
      return this.form;
    },
    debouncedSaveCommunication(): (...args: any[]) => void {
      return debounce(this.saveCommunication, 500);
    },
  },
});
