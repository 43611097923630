
import Vue from "vue";
import formService from "@/services/formService";
import themeService from "@/services/themeService";
import FormsTreeView from "@/components/form/FormsTreeView.vue";
import contactService from "@/services/contactService";
import contactGroupService from "@/services/contactGroupService";
import store from "@/store";
import FormManager from "@/components/form/FormManager.vue";
import { Contact, ContactGroup, Folder, Form, Theme } from "../../../shared/models";
import FormGraphView from "@/components/form/FormGraphView.vue";
import graphViewIcon from "@/assets/graph-view-icon.png";

export default Vue.extend({
  name: "forms",
  components: { FormsTreeView, FormManager, FormGraphView },
  data() {
    return {
      graphViewIcon,
      loading: false,
      forms: [] as Form[],
      folders: [] as Folder[],
      selectedForm: null as Form | null,
      contacts: [] as Contact[],
      contactGroups: [] as ContactGroup[],
      themes: [] as Theme[],
      treeKey: 100,
      viewMode: 0,
    };
  },
  async created() {
    try {
      this.loading = true;
      await this.loadAllForms();
      await this.loadContacts();
      await this.loadContactGroups();
      await this.loadAllThemes();
      if (this.selectedForm && this.$route.query.formId != this.selectedForm.id) {
        await this.$router.replace({ query: { ...this.$route.query, formId: this.selectedForm.id } });
      }
    } catch (error: any) {
      console.error(error);
      this.$notification.showError(error.message);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async changeToTreeView() {
      this.forms = [];
      this.selectedForm = null;
      this.loading = true;
      this.viewMode = 0;
      await this.loadAllForms();
      this.loading = false;
    },
    async loadAllThemes() {
      const docs = await themeService.getAllThemes(this.companyId);
      docs.forEach((doc) => {
        var u = doc;
        u.id = doc.id;
        this.themes.push(u);
      });
    },
    findForm(formId) {
      let forms = [...this.forms];
      let childs = [] as Form[];
      for (const item of forms) {
        if (item.children && item.children.length > 0) childs = [...this.extractChilds(item.children), ...childs];
      }
      forms = [...childs, ...forms];
      return forms.find((item) => item.id == formId && !item.isLink);
    },
    extractChilds(childs) {
      let forms = [] as any[];
      childs.forEach((item) => {
        forms.push(item);
        if (item.children && item.children.length > 0) forms = [...this.extractChilds(item.children), ...forms];
      });
      return forms;
    },
    openChildForm(child: any) {
      const form = this.findForm(child.id);
      if (form) this.selectForm(form);
    },
    async loadAllForms(successCallBack?, failCallBack?) {
      try {
        let forms: Form[] = [];
        if (this.viewMode == 1) {
          const result = await formService.getFullFormDetails(this.companyId, true, store.state.userDetail.userData.id ?? "");
          forms = result.forms;
          this.folders = result.folders;
        } else {
          forms = await formService.getFullFormDetails(this.companyId, false, store.state.userDetail.userData.id ?? "");
        }

        if (!this.selectedForm && forms.length) {
          let f = forms.find((item) => !(item as any).isFolder);
          const queryFormId = this.$route.query["formId"];
          const queryLinkId = this.$route.query["linkId"];
          if (queryFormId) {
            const doc = await formService.getById(queryFormId.toString());
            if (doc.exists) {
              f = doc.data() as Form;
            }
          }
          if (queryLinkId) {
            if (this.$route.query.tab != "1") {
              await this.$router.replace({ query: { ...this.$route.query, tab: "1" } });
            }
          }
          if (f != undefined) await this.selectForm(f, false);
        }
        this.forms = forms;
        if (successCallBack) {
          successCallBack();
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
        if (failCallBack) {
          failCallBack(error);
        }
      }
    },
    async reloadForm(f: Form, successCallBack?, failCallBack?) {
      const rootFormId = f.key.split("/")[0];
      let rootForm = this.forms.find((x) => x.id == rootFormId);
      try {
        if (rootForm) (rootForm as any).loading = true;
        this.treeKey++;
        const doc = await formService.getById(rootFormId);
        var form = doc.data() as Form;
        await this.loadFormChildren(form, undefined);
        await this.loadUnseenSessionsCount(form, []);
        if (rootForm) {
          rootForm.children = form.children;
          (rootForm as any).unseenSessionCount = (form as any).unseenSessionCount;
        }
        if (successCallBack) {
          successCallBack();
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
        if (failCallBack) {
          failCallBack(error);
        }
      } finally {
        if (rootForm) (rootForm as any).loading = false;
        this.treeKey++;
      }
    },
    async loadContacts(callBack?) {
      if (this.userId !== "") {
        this.contacts = [];
        const docs = await contactService.getContacts(this.companyId);
        if (docs) {
          docs.forEach((doc) => {
            var u = doc.data();
            u.id = doc.id;
            this.contacts.push(u);
          });
        }
      }
    },
    async loadContactGroups(callBack?) {
      if (this.userId !== "") {
        this.contactGroups = [];
        const docs = await contactGroupService.getContactGroups(this.companyId);
        if (docs) {
          docs.forEach((doc) => {
            var u = doc.data();
            u.id = doc.id;
            this.contactGroups.push(u);
          });
        }
      }
    },
    async loadFormChildren(form, callBack?, promises?: Promise<any>[]) {
      try {
        const children = await formService.getFormChildrenAndLinks(form, this.companyId);
        form.children = children;
        for (const child of children) {
          if (!child.isLink) {
            if (promises) {
              promises.push(this.loadFormChildren(child, undefined, promises));
            } else {
              await this.loadFormChildren(child);
            }
          }
        }
        if (callBack) {
          callBack();
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
        form.children = [];
      }
    },
    async loadUnseenSessionsCount(form: any, parentForms: any[], promises?: Promise<any>[]) {
      try {
        const count = await formService.getUnseenSessionsCount(form, this.companyId, store.state.userDetail.userData.id);
        form.unseenSessionCount = count;
        for (const p of parentForms) {
          p.unseenSessionCount += count;
        }
        for (const child of form.children) {
          if (!child.isLink) {
            if (promises) {
              promises.push(this.loadUnseenSessionsCount(child, [...parentForms, form], promises));
            } else {
              await this.loadUnseenSessionsCount(child, [...parentForms, form], promises);
            }
          }
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      }
    },
    async selectForm(form: Form, changeQuery = true, cb?) {
      try {
        this.selectedForm = formService.fixForm(form);
        if (changeQuery && this.selectedForm && this.$route.query.formId != this.selectedForm.id) {
          await this.$router.replace({ query: { ...this.$route.query, formId: this.selectedForm.id } });
        }
      } finally {
        if (cb) {
          cb();
        }
      }
    },
    async selectFormById(id: string) {
      const form = (await formService.getById(id)).data() as Form;
      await this.selectForm(form);
    },
    async showPublishes(form: Form) {
      this.selectForm(form);
      if (this.$route.query.tab != "1") {
        await this.$router.replace({ query: { ...this.$route.query, tab: "1" } });
      }
    },
    async showSessionsAndChildren(form: Form) {
      this.selectForm(form);
      if (this.$route.query.tab != "5") {
        await this.$router.replace({ query: { ...this.$route.query, tab: "5" } });
      }
    },
    async showLink(form: Form) {
      const filterResult = this.forms.find((x) => x.id == form.id);
      if (filterResult) {
        this.selectForm(filterResult);
        // if (this.$route.query.tab != "0") {
        //   await this.$router.replace({  query: { ...this.$route.query, tab: "0" } });
        // }
      }
    },
    async moveFromFolderToRoot(form: Form, cb) {
      try {
        this.loading = true;
        await formService.saveForm(form);
        await cb();
      } finally {
        this.loading = false;
      }
    },
    async moveFormToFolder(formId: string, folderId: string, cb) {
      try {
        this.loading = true;
        await formService.updateFolderId(formId, folderId);
        await cb();
      } finally {
        this.loading = false;
      }
    },
    async moveForm(formId: string, newParentKey: string, cb) {
      try {
        this.loading = true;
        const form = (await formService.getById(formId)).data() as Form;
        await formService.changeFormParentKey(form, newParentKey, this.companyId);
        await cb();
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    queryFormIdValue(newValue) {
      if (newValue && newValue != this.selectedForm?.id) {
        this.selectFormById(newValue);
      }
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    userId(): string {
      return store.getters.userId;
    },
    queryFormIdValue(): string {
      return (this.$route.query.formId || "").toString();
    },
  },
});
