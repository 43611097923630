
import Vue from "vue";
import store from "@/store";
import { Setting, Token } from "../../../shared/models";
import { outputEndpointBaseURL } from "../../../shared/constants";
import jwt from "jwt-simple";

export default Vue.extend({
  model: {
    prop: "item",
    event: "input",
  },
  props: {
    companyId: { type: String, required: true },
    formId: { type: String, default: null },
    linkId: { type: String, default: null },
    bundleMode: { type: Boolean, default: false },
    bundleExampleMode: { type: Boolean, default: false },
  },
  computed: {
    jwtClientSecret() {
      return (store.getters.setting as Setting).jwtClientSecret;
    },
    getEmbeddedFormCode(): string {
      let comanyId = this.companyId;
      let limkId = this.linkId;
      let formId = this.formId;
      const payload: Token = {
        sessionId: null,
        formId: formId,
        companyId: comanyId,
        linkId: limkId,
        theme: null,
        contactIds: [],
        contactGroupIds: [],
        clickerEmailAddress: null,
        parentSessionId: null,
      };
      const token = jwt.encode(payload, this.jwtClientSecret);
      if (!this.bundleMode && !this.bundleExampleMode) {
        return (
          '<<span style="color:blue;">div</span> <span style="color:aliceblue;">class</span>="adf-embedded" <span style="color:aliceblue;">key</span>="' +
          token +
          '"><<span>/</span><span style="color:blue;">div</span>>' +
          "\n" +
          `<<span style="color:blue;">script</span> <span style="color:aliceblue;">src</span>="${outputEndpointBaseURL.ensureNotEndsWith(
            "/"
          )}/Embedded.js?v=${Date.now()}">` +
          "<<span>/</span><span style='color:blue;'>script</span>>"
        );
      } else if (this.bundleMode) {
        return (
          `<<span style="color:blue;">script</span> <span style="color:aliceblue;">src</span>="${outputEndpointBaseURL.ensureNotEndsWith(
            "/"
          )}/Bundle.js?v=${Date.now()}">` + "<<span>/</span><span style='color:blue;'>script</span>>"
        );
      } else if (this.bundleExampleMode) {
        return (
          `var <span style="color:blue;">host_data</span> = [ { <span style="color:blue;">key</span>: "Key Name", <span style="color:blue;">value</span>: "Key Value" } ];` +
          "\n" +
          `<b>afPushData</b>(<span style="color:blue;">host_data</span>);`
        );
      } else {
        return "";
      }
    },
  },
});
