export function throttle(func, delay: number) {
  let nextAllowed = 0;
  return {
    run(...args) {
      const now = Date.now();
      if (now < nextAllowed) {
        return;
      }
      nextAllowed = now + delay;
      func(...args);
    },
    reset() {
      nextAllowed = 0;
    },
  };
}

export function debounce(func, delay: number) {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}
