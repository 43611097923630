
import Vue from "vue";
import store from "@/store";
import TextFieldBox from "../field-box/TextFieldBox.vue";
import ThemeSelector from "../ThemeSelector.vue";
import AutoCompleteBox from "../field-box/AutoCompleteBox.vue";
import { Publish, Form, LinkItem, Contact, ContactGroup, Theme } from "../../../../shared/models";

export default Vue.extend({
  components: { TextFieldBox, AutoCompleteBox, ThemeSelector },
  props: {
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
    forms: {
      type: Array as () => Form[],
      required: true,
      default: () => [],
    },
    contacts: {
      type: Array as () => Contact[],
      required: true,
      default: () => [],
    },
    contactGroups: {
      type: Array as () => ContactGroup[],
      required: true,
      default: () => [],
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    publish: {
      type: Object as () => Publish,
      required: true,
      default: null,
    },
    themes: {
      type: Array as () => Theme[],
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      formIsValid: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        depth: (val) => (val >= 0 && val <= 5) || `Depth must be between 0 and 5`,
        zeroOrPositive: (val) => val >= 0 || `Invalid value`,
        email: (value) => {
          const pattern =
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid email.";
        },
      },
    };
  },
  watch: {
    publish: {
      handler(newValue, oldValue) {
        this.handleChange();
      },
      deep: true,
    },
  },
  methods: {
    deletePublish() {
      this.$emit("delete", this.publish);
    },
    handleChange() {
      this.$emit("change", this.publish);
      (this.$refs.form as any).validate();
    },
    setSelectedTheme(theme: string) {
      this.computedPublish.selectedTheme = theme;
    },
    openPopup() {
      this.$emit("share", this.publish);
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    computedPublish(): Publish {
      return this.publish;
    },
    links(): LinkItem[] {
      return this.forms.map((x) => ({ id: x.id, name: x.name, delayed: false }));
    },
    getContacts(): LinkItem[] {
      return this.contacts.map((x) => ({ id: x.id, name: x.firstName + " " + x.lastName, delayed: false }));
    },
    getContactGroups(): LinkItem[] {
      return this.contactGroups.map((x) => ({ id: x.id, name: x.groupName, delayed: false }));
    },
  },
});
