
import Vue from "vue";

export default Vue.extend({
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
    },
    exitEditModeOnEnter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
      defaultMins: ["15", "30", "45", "60"],
      selectedTime: this.value || "15",
      customClicked: false,
      customInput: "",
    };
  },
  watch: {
    customInput(value) {
      this.handleTimeClick(true, value);
    },
  },
  methods: {
    handleTimeClick(customTime: boolean, value) {
      if (!customTime) {
        this.selectedTime = value;
      } else {
        this.selectedTime = this.customInput;
        (this.$refs.customInputBox as HTMLElement).focus();
      }
      this.customClicked = customTime;
      this.$emit("input", this.selectedTime);
    },
  },
});
