
import Vue from "vue";
import TextFieldBox from "../field-box/TextFieldBox.vue";
import TextAreaBox from "../field-box/TextAreaBox.vue";
import AppointmentElement from "../appointment/AppointmentElement.vue";
import RadioButtonsElement from "../radio-button/RadioButtonsElement.vue";
import DownloadPDFElement from "../download-pdf/DownloadPDFElement.vue";

export default Vue.extend({
  model: {
    prop: "item",
    event: "input",
  },
  components: {
    TextFieldBox,
    TextAreaBox,
    AppointmentElement,
    RadioButtonsElement,
    DownloadPDFElement,
  },
  props: {
    item: { type: Object },
  },
  data() {
    return {
      innerItem: this.item,
    };
  },
  watch: {
    item(value) {
      this.innerItem = value;
    },
  },
  methods: {
    handleChange() {
      this.$emit("change");
    },
  },
});
