
import Vue from "vue";
import formService from "@/services/formService";
import themeService from "@/services/themeService";
import store from "@/store";
import { Form, LayoutSetting, Publish, Theme } from "../../../../shared/models";
import publishService from "@/services/publishService";

export default Vue.extend({
  props: {
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      layouts: [] as LayoutSetting[],
      loadingLayouts: false,
      parsInt: parseInt,
      settings: {
        alternating: this.form.layoutSettings?.alternating || false,
        showDivider: this.form.layoutSettings?.showDivider || false,
        wholeAreaClickable: this.form.layoutSettings?.wholeAreaClickable || false,
        textAlignment: this.form.layoutSettings?.textAlignment || null,
        columnsPerRow: this.form.layoutSettings?.columnsPerRow || 1,
        layout: this.form.layoutSettings?.layout || "Default",
        enabled: this.form.layoutSettings?.enabled || false,
      },
      rendering: false,
      previewHtml: "",
      selectedLink: null as string | null,
      selectedTheme: null as string | null,
      themes: [] as Theme[],
      textAlignments: [
        { id: "left", title: "Left" },
        { id: "center", title: "Center" },
        { id: "right", title: "Right" },
      ],
      publishes: [] as Publish[],
    };
  },
  async created() {
    this.loadThemes();
    this.loadPublishes();
    await this.loadLayouts();
    await this.render();
  },
  methods: {
    async loadLayouts() {
      try {
        this.loadingLayouts = true;
        this.layouts = await formService.getFormLayouts();
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loadingLayouts = false;
      }
    },
    async render() {
      try {
        if (this.settings.layout && this.settings.enabled) {
          this.rendering = true;
          const renderResponse = await formService.renderForPreview(this.form.key, this.selectedLink, this.selectedTheme, this.settings);
          this.previewHtml = renderResponse.html;
        } else {
          this.previewHtml = "";
        }
      } catch (error: any) {
        this.previewHtml = "An error occurred while rendering preview.";
      } finally {
        this.rendering = false;
      }
    },
    async loadThemes() {
      this.themes = await themeService.getAllThemes(this.companyId);
    },
    async loadPublishes() {
      this.publishes = await publishService.getFormPublishes(this.form.id, this.companyId);
    },
    handleFormChange(form, successCallBack?, errorCallBack?) {
      this.$emit("change", form, successCallBack, errorCallBack);
    },
  },
  watch: {
    settings: {
      handler(value) {
        this.computedForm.layoutSettings = value;
        this.handleFormChange(this.computedForm);
        this.render();
      },
      deep: true,
    },
    selectedLink() {
      this.render();
    },
    selectedTheme() {
      this.render();
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    computedForm(): any {
      return this.form;
    },
  },
});
