import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
import { Publish } from "../models";

export default class PublishService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public async getById(id: string) {
    return await this.db.collection("Publishes").doc(id).get();
  }

  public async getAll(companyId: string) {
    return await this.db.collection("Publishes").where("companyId", "==", companyId).orderBy("order").get();
  }

  public async getListByFormId(companyId: string, formId: string) {
    return await this.db.collection("Publishes").where("companyId", "==", companyId).where("formId", "==", formId).orderBy("order").get();
  }

  public async getFormPublishes(formId: string, companyId: string) {
    const childrenData = await this.getListByFormId(companyId, formId);
    const publishes = [] as Publish[];
    childrenData.forEach((doc) => {
      const f = doc.data() as Publish;
      if (f.companyId && f.companyId == companyId) {
        f.id = doc.id;
        publishes.push(f);
      }
    });
    return publishes;
  }

  public async getList(companyId: string) {
    return await this.db.collection("Publishes").where("companyId", "==", companyId).get();
  }

  public async deletePublish(id: string) {
    return await this.db.collection("Publishes").doc(id).delete();
  }

  public async updatePublish(publish: Publish) {
    return await this.db.collection("Publishes").doc(publish.id).set(publish, { merge: true });
  }
}
