
import Vue from "vue";
import SessionDataValues from "@/components/audience/SessionDataValues.vue";
import sessionService from "@/services/sessionService";
import { Session } from "../../../shared/models";

export default Vue.extend({
  components: { SessionDataValues },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      session: null as Session | null,
    };
  },
  mounted() {
    this.loadSession();
  },
  methods: {
    async loadSession() {
      try {
        this.loading = true;
        this.session = (await sessionService.getById(this.id)).data() as Session;
      } catch (error: any) {
        this.$notification.showError(error.message);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    companyId(): string {
      return this.$store.getters.companyId;
    },
  },
});
