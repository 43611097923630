
import Vue from "vue";
import TextFieldBox from "../field-box/TextFieldBox.vue";
import AutoCompleteBox from "../field-box/AutoCompleteBox.vue";
import FormItem from "./FormItem.vue";
import IdGenerator from "../../../../shared/extensions/IdGenerator";
import { storage } from "../../../../shared/plugins/firebase-app";
import { axiosStatic } from "@/plugins/axios";
import { mimeTypes } from "../../../../shared/constants";
import formService from "@/services/formService";
import ThemeSelector from "../ThemeSelector.vue";
import EditorBox from "../field-box/EditorBox.vue";
import store from "@/store";
import { Form, Theme, LinkableElement, LinkItem } from "../../../../shared/models";

export default Vue.extend({
  components: {
    TextFieldBox,
    AutoCompleteBox,
    FormItem,
    ThemeSelector,
    EditorBox,
  },
  props: {
    forms: {
      type: Array as () => Form[],
      required: true,
      default: () => [],
    },
    form: {
      type: Object as () => Form,
      required: true,
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    themes: {
      type: Array as () => Theme[],
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      addingChild: false,
      addNewLinkLoading: false,
      files: [],
      formItems: [
        { icon: "mdi-form-textbox", type: "TextBox" },
        { icon: "mdi-checkbox-marked-outline", type: "CheckBox" },
        { icon: "mdi-label-outline", type: "Label" },
        { icon: "mdi-email", type: "Email", title: "Email Address" },
        { icon: "mdi-form-textbox", type: "TextArea", title: "Text Area" },
        { icon: "mdi-application-brackets-outline", type: "iFrame", title: "iFrame" },
        // { icon: "mdi-calendar", type: "Appointment", title: "Appointment" },
        { icon: "mdi-radiobox-marked", type: "RadioButton", title: "Radio Button" },
        { icon: "mdi-download", type: "DownloadPDF", title: "Download PDF" },
      ],
      dragOptions: {
        animation: 200,
      },
      shareFileType: this.form.media ? 1 : 2,
      elementLayoutItems: ["Stacked", "Grid", "Float"],
      formFiles: () => {
        return this.form.media
          ? [
              {
                source: this.form.media,
                options: {
                  type: "local",
                },
                metadata: {
                  poster: this.form.media,
                },
              },
            ]
          : [];
      },
      server: () => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const $this: any = this;
        return {
          process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            var storageRef = storage.ref($this.form.id + $this.getFileExtension(file.name));
            const uploadTask = storageRef.put(file);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                progress(true, snapshot.bytesTransferred, snapshot.totalBytes);
              },
              (e) => {
                console.error(e);
                error(e.message);
              },
              async () => {
                // Handle successful uploads on complete
                try {
                  const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
                  $this.computedForm.media = downloadURL;
                  $this.handleFormChange($this.computedForm);
                  load(downloadURL);
                } catch (e: any) {
                  console.error(e);
                  error(e.message);
                }
              }
            );
            return {
              abort: () => {
                uploadTask.cancel();
                abort();
              },
            };
          },
          async remove(file, success, error) {
            try {
              var storageRef = storage.ref($this.getFileNameFromUrl(file));
              await storageRef.delete();
              $this.computedForm.media = null;
              $this.handleFormChange($this.computedForm);
            } catch (e) {
              error(e);
              return;
            }
            success();
          },
          load: async (source, load, error, progress, abort, headers) => {
            try {
              const response = await axiosStatic.get(source, {
                responseType: "arraybuffer",
                onDownloadProgress: (progressEvent) => {
                  progress(true, progressEvent.loaded, progressEvent.total);
                },
              });
              const fileName = $this.getFileNameFromUrl(source);
              const fileExtension = $this.getFileExtension(fileName);
              const blob = new Blob([new Uint8Array(response.data, 0, response.data.byteLength)], { type: mimeTypes[fileExtension] });
              load(blob);
            } catch (e) {
              console.error(e);
              error(e);
            }
            return {
              abort: () => {
                abort();
              },
            };
          },
        };
      },
      loadingLinkableElements: false,
      linkableElements: [] as LinkableElement[],
      formHostData: [] as string[],
      showLinkRenameDialog: false,
      linkLabel: "",
      selectedLinkIndex: 0,
    };
  },
  async created() {
    await this.loadLinkableElements();
    await this.loadParentHostData();
  },
  watch: {
    // "form.links": {
    //   handler(newValue, oldValue) {
    //     this.handleFormChange(this.form, () => {
    //       this.loadLinkableElements();
    //     });
    //   },
    //   deep: true,
    // },
    // "form.name"(newValue, oldValue) {
    //   this.handleFormChange(this.form, null, null);
    // },
    // "form.optionLabel"(newValue, oldValue) {
    //   this.handleFormChange(this.form, null, null);
    // },
    // "form.archived"(newValue, oldValue) {
    //   this.handleFormChange(this.form, null, (error) => {
    //     this.computedForm.archived = oldValue;
    //   });
    // },
    // "form.question"(newValue, oldValue) {
    //   this.handleFormChange(this.form);
    // },
    // "form.media"(newValue, oldValue) {
    //   this.handleFormChange(this.form);
    // },
    // "form.youtube"(newValue, oldValue) {
    //   this.handleFormChange(this.form);
    // },
    // "form.elements": {
    //   handler(newValue, oldValue) {
    //     this.handleFormChange(this.form);
    //   },
    //   deep: true,
    // },
    // "form.selectedTheme"(newValue, oldValue) {
    //   this.handleFormChange(this.form);
    // },
    // "form.actionButtonFullWidth"(newValue, oldValue) {
    //   this.handleFormChange(this.form);
    // },
    // "form.isTerminalNode"(newValue, oldValue) {
    //   this.handleFormChange(this.form);
    // },
  },
  methods: {
    removeSelectedHostData(item) {
      this.computedForm.hostDataParameters?.splice(this.computedForm.hostDataParameters.indexOf(item), 1);
      this.handleFormChange(this.computedForm);
    },
    showRenameDialog(index: number) {
      this.selectedLinkIndex = index;
      this.linkLabel = this.computedForm.links[index].label || this.computedForm.links[index].name;
      this.showLinkRenameDialog = true;
    },
    renameLink(index: number, value: string) {
      this.computedForm.links[index].label = value;
      const l = this.links.find((x) => x.id == this.computedForm.links[index].id);
      if (l) {
        l.name = this.computedForm.links[index].label || this.computedForm.links[index].name;
      }
      this.linkLabel = "";
      this.showLinkRenameDialog = false;
      this.handleFormChange(this.form, () => {
        this.loadLinkableElements();
      });
    },
    async handleChildDragEnd() {
      this.$emit("childrenOrderChanged", this.childrenThatNotLink, () => {
        this.loadFormChildren(this.form);
      });
    },
    async loadLinkableElements() {
      try {
        this.loadingLinkableElements = true;
        this.linkableElements = await formService.getLinkableElements(this.companyId, this.form.id);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.loadingLinkableElements = false;
      }
    },
    async loadParentHostData() {
      if (this.computedForm.parent) {
        this.formHostData = this.computedForm.hostDataParameters ? this.computedForm.hostDataParameters : [];
      } else {
        const prantForm = this.forms.find((item) => item.id == this.computedForm.parentKey.split("/")[0]);
        this.formHostData = prantForm && prantForm.hostDataParameters ? prantForm.hostDataParameters : [];
      }
    },
    setSelectedTheme(theme: string) {
      this.computedForm.selectedTheme = theme;
      this.handleFormChange(this.computedForm);
    },
    handleFormChange(form: Form, successCallBack?, errorCallBack?) {
      this.$emit("change", form, successCallBack, errorCallBack);
    },
    cloneFormItem(x) {
      return { ...x, id: IdGenerator.newId() };
    },
    async removeFormData(index) {
      try {
        const r = await this.$swal({
          title: "Delete element",
          text: "Are you sure to delete this element?",
          buttons: [
            {
              text: "No",
              value: false,
              visible: true,
              closeModal: true,
            },
            {
              text: "Yes",
              value: true,
              visible: true,
              closeModal: true,
            },
          ],
        });
        if (r === true) {
          const usageOfForm = await formService.getElementUsages(this.computedForm.companyId, this.computedForm.elements[index].id);
          if (usageOfForm.size > 0) {
            throw new Error(`You can not delete this element. because the element is used in ${usageOfForm.size} another form(s).`);
          } else {
            this.computedForm.elements.splice(index, 1);
            this.handleFormChange(this.computedForm);
          }
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      }
    },
    addToFormData(formItem) {
      this.$set(this.computedForm, "elements", [...this.computedForm.elements, this.cloneFormItem(formItem)]);
      this.handleFormChange(this.computedForm);
    },
    checkMove(evt, originalEvent) {
      return evt.related == document.querySelector(".form-data-box > span") || evt.related.classList.contains("form-data-box-row");
    },
    getFileExtension(fileName) {
      const i = fileName.lastIndexOf(".");
      if (i >= 0) {
        return fileName.substring(i, fileName.length);
      }
      return "";
    },
    getFileNameFromUrl(url = "") {
      const a = url.indexOf(this.form.id);
      let b = url.indexOf("?");
      if (b < 0) b = url.length;
      const fileName = url.substring(a, b);
      return fileName;
    },
    async addNewLink() {
      this.addNewLinkLoading = true;
      const id = IdGenerator.newId();
      const newForm: Form = {
        id,
        key: id,
        name: `New Form ${this.forms.length + 1}`,
        question: "",
        optionLabel: "",
        parent: true,
        media: null,
        youtube: null,
        companyId: this.companyId,
        archived: false,
        submission: {
          emailNotification: false,
          enabled: false,
        },
        elements: [],
        folderId: "",
        links: [],
        linkIds: [],
        selectedTheme: null,
        visits: [],
        layoutSettings: null,
        order: this.forms.length,
        parentKey: "",
      };
      await formService.saveForm(newForm);
      this.$emit("newLinkAdded", async () => {
        await this.$nextTick();
        const newItem: LinkItem = { id: newForm.id, name: newForm.name, delayed: false, label: "" };
        this.$set(this.computedForm, "links", [...this.computedForm.links, newItem]);
        this.handleFormChange(this.computedForm, async () => {
          await this.loadLinkableElements();
        });
        this.addNewLinkLoading = false;
      });
    },
    async addExistingLink() {
      const newItem: LinkItem = { id: this.links[0].id, name: this.links[0].name, delayed: false, label: "" };
      this.$emit("linkAdded", {
        parentId: this.computedForm.id,
        id: this.links[0].id,
        name: this.links[0].name,
        delayed: false,
        label: "",
      });
      this.$set(this.computedForm, "links", [...this.computedForm.links, newItem]);
      this.handleFormChange(this.computedForm, async () => {
        await this.loadLinkableElements();
      });
    },
    async removeLink(index) {
      const r = await this.$swal({
        title: "Delete linked form",
        text: "Are you sure to delete this linked form?",
        buttons: [
          {
            text: "No",
            value: false,
            visible: true,
            closeModal: true,
          },
          {
            text: "Yes",
            value: true,
            visible: true,
            closeModal: true,
          },
        ],
      });
      if (r === true) {
        this.computedForm.links.splice(index, 1);
        this.handleFormChange(this.computedForm, async () => {
          await this.loadLinkableElements();
          await this.loadFormChildren(this.computedForm);
        });
      }
    },
    async loadFormChildren(form, callBack?) {
      try {
        const children = await formService.getFormChildrenAndLinks(form, this.companyId);
        form.children = children;
        if (callBack) {
          callBack();
        }
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
        form.children = [];
      }
    },
    async archiveForm(childForm) {
      try {
        childForm.archived = true;
        this.handleFormChange(childForm);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      }
    },
    async changeFormName(childForm, e) {
      try {
        childForm.name = e;
        this.handleFormChange(childForm);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      }
    },
    async openChildForm(childForm) {
      this.$emit("openChildForm", childForm);
    },
    async changeLink(index, link) {
      try {
        this.computedForm.links[index] = link;
        this.handleFormChange(this.computedForm, async () => {
          await this.loadLinkableElements();
          await this.loadFormChildren(this.computedForm);
        });
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      }
    },
    async addNewChild() {
      try {
        this.addingChild = true;
        const id = IdGenerator.newId();
        const child = {
          id,
          key: this.computedForm.key + "/" + id,
          name: `Child ${this.childrenThatNotLink.length + 1}`,
          question: "",
          optionLabel: "",
          parent: false,
          media: null,
          youtube: null,
          companyId: this.companyId,
          archived: false,
          submission: {
            emailNotification: false,
            enabled: false,
          },
          elements: [],
          links: [],
          linkIds: [],
          folderId: "",
          order: this.childrenThatNotLink.length,
          selectedTheme: null,
          visits: [],
          layoutSettings: null,
          actionButtonFullWidth: true,
          isTerminalNode: false,
          parentKey: this.computedForm.key,
        };
        await formService.saveForm(child);
        await this.loadFormChildren(this.computedForm);
        this.$emit("formAdded", child);
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
      } finally {
        this.addingChild = false;
      }
    },
    findRootItem(items, id) {
      for (const item of items) {
        if (item.id === id) {
          return item.id;
        }
        const child = this.findRootItem(item.children, id);
        if (child) {
          return item.id;
        }
      }
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    notSelectedForms(): any {
      return (selectedLink) => {
        return this.links.filter((x) => {
          return (!!selectedLink && !!x && x.id === selectedLink.id) || this.computedForm.links.findIndex((a) => !!a && a.id === x.id) < 0;
        });
      };
    },
    links(): { id: string; name: string }[] {
      let forms = [...this.forms];
      let folderChilds: Form[] = [];
      const folderIndex: number[] = [];
      forms.forEach((item) => {
        if ((item as any).isFolder) {
          if (item.children) {
            folderIndex.push(forms.findIndex((i) => (i.id = item.id)));
            folderChilds = [...folderChilds, ...item.children];
          }
        }
      });
      folderIndex.forEach((item) => {
        forms.splice(item, 1);
      });
      forms = [...forms, ...folderChilds];

      return forms.map((x) => {
        const f = this.form.links.find((f) => !!f && f.id === x.id);
        return { id: x.id, name: f && f.label ? f.label : x.optionLabel || x.name };
      });
    },
    computedForm(): Form {
      return this.form;
    },
    childrenThatNotLink(): Form[] {
      return (this.computedForm as any).children.filter((x) => !x.isLink);
    },
  },
});
