
import Vue from "vue";
import TextFieldBox from "../field-box/TextFieldBox.vue";
import { Form } from "../../../../shared/models";
import UpcomingCommunications from "@/components/form/UpcomingCommunications.vue";
import PastCommunications from "@/components/form/PastCommunications.vue";
import DateRangePicker from "@/components/field-box/DateRangePicker.vue";

export default Vue.extend({
  components: { TextFieldBox, UpcomingCommunications, PastCommunications, DateRangePicker },
  props: {
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      selectedTab: "upcoming",
      dateRange: [],
      recipient: "",
      refreshKey: 0,
    };
  },
  methods: {},
  computed: {},
});
