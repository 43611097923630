
import Vue from "vue";

export default Vue.extend({
  inheritAttrs: false,
  props: {
    showOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    showRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
    showArchive: {
      type: Boolean,
      required: false,
      default: false,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    fieldBox: {
      type: Boolean,
      required: false,
      default: true,
    },
    solo: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    handleInputChange($event) {
      if (this.$attrs.min && $event < this.$attrs.min) {
        this.$emit("input", "");
        setTimeout(() => {
          this.$emit("input", this.$attrs.min);
        }, 500);
        return;
      }
      if (this.$attrs.max && $event > this.$attrs.max) {
        this.$emit("input", "");
        setTimeout(() => {
          this.$emit("input", this.$attrs.max);
        }, 500);
        return;
      }
      this.$emit("input", this.$attrs.type && this.$attrs.type === "number" ? $event || 0 : $event || "");
    },
  },
});
