
import * as d3 from "d3";
import Vue from "vue";
import store from "@/store";
import { Contact, ContactGroup, Folder, Form, Theme } from "../../../../shared/models";
import FormManager from "@/components/form/FormManager.vue";
import treeViewIcon from "@/assets/tree-view-icon.png";

export default Vue.extend({
  components: { FormManager },
  props: {
    contacts: {
      type: Array as () => Contact[],
      required: true,
    },
    contactGroups: {
      type: Array as () => ContactGroup[],
      required: true,
    },
    themes: {
      type: Array as () => Theme[],
      required: true,
    },
    forms: {
      type: Array as () => Form[],
      required: true,
      default: () => [],
    },
    folders: {
      type: Array as () => Folder[],
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      treeViewIcon,
      currenttab: "",
      selectedForm: null as Form | null,
      sessions: [],
      showloading: false,
      eventList: [],
      activeNode: null,
      showRightPanel: false,
      selectedTab: 0,
      loading: false,
      nodesList: [] as any[],
      linkList: [] as any[],
      groupNodes: {},
      groupLinks: {},
      mappedNode: [] as any[],
      simulation: {},
      color: {},
      svg: {},
      colorDetails: [] as any[],
      selectedFolders: [] as any[],
      zoom: {},
    };
  },
  async mounted() {
    await this.loadAllForms();
  },
  methods: {
    folderFilterChanged() {
      let data = [...this.forms];
      if (this.selectedFolders.length > 0) {
        data = data.filter((item) => this.selectedFolders.includes(item.folderId));
        const linkedFormIds = this.extractLinkedFormIds(data);
        if (linkedFormIds && linkedFormIds.length > 0) {
          const pranetLinkedForms = this.getLinkedParentForms(this.forms, linkedFormIds);
          data = [...data, ...pranetLinkedForms];
        }
      }

      this.createtree(data);
    },
    getLinkedParentForms(forms: Form[], linkedFormIds: string[]) {
      const linkedForms: Form[] = [];
      linkedFormIds.forEach((link) => {
        const parentForm = forms.find((item) => item.id == link);
        if (parentForm) {
          if (!linkedForms.includes(parentForm)) {
            linkedForms.push(parentForm);
          }
        } else {
          let parentId = null;
          forms.forEach((item) => {
            parentId = this.getChildParentFormId(item.children ?? [], link);
          });
          if (parentId) {
            const childParentForm = forms.find((item) => item.id == parentId);
            if (childParentForm && !linkedForms.includes(childParentForm)) {
              linkedForms.push(childParentForm);
            }
          }
        }
      });
      return linkedForms;
    },
    getChildParentFormId(forms: Form[], linkedFormId: string) {
      let parentId: string | null = null;
      forms.forEach((item) => {
        if (item.id == linkedFormId) {
          const parentKey = item.parentKey.split("/");
          parentId = parentKey ? parentKey[0] : item.parentKey;
        } else {
          this.getChildParentFormId(item.children ?? [], linkedFormId);
        }
      });

      return parentId;
    },
    extractLinkedFormIds(forms: Form[]) {
      let linkedIds = [] as any[];
      forms.forEach((item) => {
        const formLinks = item.linkIds && item.linkIds.length > 0 ? item.linkIds : [];
        linkedIds = [...linkedIds, ...formLinks];
        if (item.children && item.children.length > 0) linkedIds = [...linkedIds, ...this.extractLinkedFormIds(item.children ?? [])];
      });
      const pranetLinkedForms = this.getLinkedParentForms(this.forms, linkedIds);
      if (pranetLinkedForms && pranetLinkedForms.length > 0) {
        linkedIds = [...linkedIds, ...this.extractLinkedFormIds(pranetLinkedForms)];
      }
      return linkedIds;
    },
    openChildForm(child: any) {
      this.selectedForm = this.mappedNode[this.mappedNode.findIndex((item) => item.id == child.id)].form;
      this.panToSelected();
    },
    panToSelected() {
      if (this.selectedForm) {
        const coordinate = (this.groupNodes as any).nodes()[0].transform.baseVal.consolidate();
        if (coordinate) {
          const bBox = (this.groupNodes as any).nodes()[0].getBBox();
          const matrix = coordinate.matrix;
          const scale = matrix.d;
          bBox;
          const node = this.nodesList.find((item) => item.id == this.selectedForm?.id);

          const nx = node.x;
          const ny = node.y;

          const w = 1300;
          const h = 900;

          const x = w / 2 - scale * (bBox.width / 2 - nx) + 70;
          const y = h / 2 - scale * (bBox.height / 2 - ny);
          (this.svg as any)
            .transition()
            .duration(750)
            .call((this.zoom as any).translateTo, x / 2, y / 2);
        }
      }
    },
    closePanel() {
      (this.$refs["panel"] as Element).classList.remove("panel-active");
      this.selectedForm = null;
    },
    async formUpdated(child: Form) {
      await this.loadAllForms();
      // let itemIndex = this.mappedNode.findIndex((item) => item.id == child.id);
      // this.mappedNode[itemIndex].name = child.name;
      // this.nodesList = this.mappedNode.map((d) => Object.create(d));
      // (this.simulation as any).nodes(this.nodesList);
      // (this.simulation as any).force(
      //   "link",
      //   d3
      //     .forceLink(this.linkList)
      //     .id((d) => d.id)
      //     .distance(60)
      // );
      // (this.simulation as any).alpha(0.3).restart();
      // this.restart();
    },
    async linkAdded(link: any) {
      await this.loadAllForms();
      // const childLinkItem = Object.create({
      //   source: link.parentId,
      //   target: link.id,
      //   type: "link",
      // });
      // this.linkList.push(childLinkItem);
      // (this.simulation as any).nodes(this.nodesList);

      // (this.simulation as any).force(
      //   "link",
      //   d3
      //     .forceLink(this.linkList)
      //     .id((d) => d.id)
      //     .distance(60)
      // );
      // (this.simulation as any).alpha(0.3).restart();
      // this.restart();
    },
    async formAdded(child: Form) {
      await this.loadAllForms();
      // child.children = [];
      // const childFormItem = {
      //   id: child.id,
      //   name: child.name,
      //   unseenSessionCount: 0,
      //   published: false,
      //   communication: false,
      //   form: child,
      //   type: 1,
      // };
      // this.mappedNode.push(childFormItem);
      // const parentKey = child.parentKey.split("/");
      // const childLinkItem = Object.create({
      //   source: parentKey[parentKey.length - 1],
      //   target: child.id,
      //   type: "form",
      // });
      // this.nodesList.push(Object.create(childFormItem));
      // this.linkList.push(childLinkItem);

      // (this.simulation as any).nodes(this.nodesList);

      // (this.simulation as any).force(
      //   "link",
      //   d3
      //     .forceLink(this.linkList)
      //     .id((d) => d.id)
      //     .distance(60)
      // );
      // (this.simulation as any).alpha(0.3).restart();
      // this.restart();
    },
    async refreshd3() {
      this.sessions = [];
      this.showloading = true;
    },
    extractChilds(data, parentId) {
      let nodes = [] as any[];
      data.forEach((item) => {
        if (!item.isLink)
          nodes.push({
            id: item.id,
            name: item.name,
            parentId: parentId,
            unseenSessionCount: item.unseenSessionCount,
            published: item.published,
            communication: item.communication,
            form: item,
            isRoot: false,
            isTerminal: item.isTerminalNode,
          });
        if (item.children && item.children.length > 0) nodes = [...this.extractChilds(item.children, item.id), ...nodes];
      });
      return nodes;
    },
    linkArc(d) {
      // const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
      // A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
      const sourceX = d.source.x;
      const sourceY = d.source.y;
      const targetX = d.target.x;
      const targetY = d.target.y;
      return `M${sourceX},${sourceY} ${targetX},${targetY}`;
    },
    getCircleSize(node) {
      const normal = 4;
      const oneIcon = 20;
      const twoIcon = 25;
      const threeIcon = 30;
      if (node.type == 1) {
        return normal;
      } else if (node.type > 1 && node.type < 5) {
        return oneIcon;
      } else if (node.type > 4 && node.type < 8) {
        return twoIcon;
      } else {
        return threeIcon;
      }
    },
    getTitlePosition(node) {
      const normal = 8;
      const oneIcon = 25;
      const twoIcon = 30;
      const threeIcon = 35;
      if (node.type == 1) {
        return normal;
      } else if (node.type > 1 && node.type < 5) {
        return oneIcon;
      } else if (node.type > 4 && node.type < 8) {
        return twoIcon;
      } else {
        return threeIcon;
      }
    },
    renderType(nodes) {
      nodes.forEach((node) => {
        const haveSession = node.unseenSessionCount > 0;
        const havePublishe = node.published;
        const haveCommunication = node.communication;
        if (!haveSession && !havePublishe && !haveCommunication) {
          node.type = 1;
        } else if (haveSession && !havePublishe && !haveCommunication) {
          node.type = 2;
        } else if (havePublishe && !haveSession && !haveCommunication) {
          node.type = 3;
        } else if (haveCommunication && !haveSession && !havePublishe) {
          node.type = 4;
        } else if (haveSession && havePublishe && !haveCommunication) {
          node.type = 5;
        } else if (haveSession && haveCommunication && !havePublishe) {
          node.type = 6;
        } else if (haveCommunication && havePublishe && !haveSession) {
          node.type = 7;
        } else if (haveSession && haveCommunication && havePublishe) {
          node.type = 8;
        }
      });
    },
    handleZoom(e, node, link) {
      node.attr("transform", e.transform);
      link.attr("transform", e.transform);
    },
    dragNode(simulation) {
      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(event, d) {
        d.fx = event.x;
        d.fy = event.y;
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

      return d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended);
    },
    createtree(data) {
      d3.select("#cont").html("");
      let mappedData = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          childs: item.children,
          unseenSessionCount: item.unseenSessionCount,
          published: item.published,
          communication: item.communication,
          form: item,
        };
      });
      let childNodes = [] as any[];
      mappedData.forEach((item) => {
        if (item.childs && item.childs.length > 0) childNodes = [...this.extractChilds(item.childs, item.id), ...childNodes];
      });
      this.mappedNode = [
        ...childNodes,
        ...mappedData.map((item) => {
          return {
            id: item.id,
            name: item.name,
            unseenSessionCount: item.unseenSessionCount,
            published: item.published,
            communication: item.communication,
            form: item.form,
            isRoot: true,
            isTerminal: item.isTerminalNode,
          };
        }),
      ];
      this.renderType(this.mappedNode);
      let linkk = [] as any[];
      this.mappedNode.forEach((item) => {
        let filtered = this.mappedNode.filter((it) => it.parentId && item.id == it.parentId);
        if (filtered && filtered.length > 0) {
          filtered.forEach((itt) => {
            linkk.push({
              source: item.id,
              target: itt.id,
              type: "form",
            });
          });
        }
        item.form.linkIds.forEach((it) => {
          linkk.push({
            source: item.id,
            target: it,
            type: "link",
          });
        });
      });
      this.nodesList = this.mappedNode.map((d) => Object.create(d));
      this.linkList = linkk.map((d) => Object.create(d));

      this.simulation = d3
        .forceSimulation(this.nodesList)
        .force(
          "link",
          d3
            .forceLink(this.linkList)
            .id((d) => d.id)
            .distance(60)
        )
        .force("charge", d3.forceManyBody().strength(-400))
        .force("x", d3.forceX())
        .force("y", d3.forceY());
      this.svg = d3.create("svg").attr("viewBox", [0, 0, 1300, 900]).attr("class", "graph-view").style("font", "12px sans-serif");

      (this.svg as any)
        .append("svg:defs")
        .append("svg:marker")
        .attr("id", "triangle")
        .attr("refX", 13)
        .attr("refY", 5)
        .attr("markerWidth", 30)
        .attr("markerHeight", 30)
        .attr("markerUnits", "userSpaceOnUse")
        .attr("orient", "auto")
        .append("path")
        .attr("d", "M 0 0 12 6 0 12 3 6")
        .style("fill", "#555");

      this.groupLinks = (this.svg as any).append("g").attr("fill", "none").attr("stroke-width", 1.5);
      // .selectAll("path")
      // .data(this.linkList)
      // .join("path")
      // .attr("stroke", (d) => (this.color as any)(d.type))
      // .attr("d", "M0,-5L10,0L0,5")
      // .attr("orient", "auto-start-reverse")
      // .attr("marker-end", "url(#triangle)");

      this.groupNodes = (this.svg as any)
        .append("g")
        .attr("fill", "currentColor")
        .attr("stroke-linecap", "round")
        .attr("stroke-linejoin", "round");

      this.restart();

      d3.select("#cont").append(() => (this.svg as any).node());
      this.loading = false;
    },
    restart() {
      let types = Array.from(new Set(this.linkList.map((d) => d.type)));
      this.color = d3.scaleOrdinal(types, d3.schemeCategory10);
      this.colorDetails = [];
      this.colorDetails.push({ name: "Root", color: "#007f69", type: "node" });
      this.colorDetails.push({ name: "Child", color: "#000000", type: "node" });
      this.colorDetails.push({ name: "Terminal", color: "#dd580c", type: "node" });
      types.forEach((item) => {
        this.colorDetails.push({ name: item, color: (this.color as any)(item), type: "link" });
      });
      let update_nodes = (this.groupNodes as any).selectAll("g").data(this.nodesList);
      (this.groupNodes as any).selectAll("g").data(this.nodesList).exit().remove();
      let nodeg = update_nodes.enter().append("g").call(this.dragNode(this.simulation));
      nodeg.merge(update_nodes);
      // let nodeg = (this.groupNodes as any).selectAll("g").data(this.nodesList);

      nodeg.append("circle").attr("r", (d) => this.getCircleSize(d));
      const sessionBox = nodeg
        .filter((d) => [2, 5, 6, 8].includes(d.type))
        .append("g")
        .attr("class", "sessionicon");
      sessionBox
        .append("svg:image")
        .attr("x", (d) => this.getSessionIconX(d))
        .attr("y", (d) => this.getSessionIconY(d))
        .attr("width", 20)
        .attr("height", 24)
        .attr("xlink:href", "../graph/info.svg");

      sessionBox
        .append("text")
        .attr("x", (d) => (d.type == 2 ? (d.unseenSessionCount > 9 ? -5 : -2) : d.unseenSessionCount > 9 ? -18 : -16))
        .attr("y", (d) => ([4, 6, 7, 8].includes(d.type) ? 12 : 4))
        .text((d) => (d.unseenSessionCount > 9 ? "9+" : d.unseenSessionCount))
        .attr("fill", "white")
        .attr("style", "font-weight: 700")
        .attr("stroke-width", 6)
        .on("click", (evt, d) => {
          this.selectedForm = this.mappedNode[d.index].form;
          this.selectedTab = 5;
          (this.$refs["panel"] as Element).classList.add("panel-active");
        });

      const publishBox = nodeg
        .filter((d) => [3, 5, 7, 8].includes(d.type))
        .append("g")
        .attr("class", "publishicon");

      publishBox
        .append("svg:image")
        .attr("x", (d) => this.getPublishIconX(d))
        .attr("y", (d) => this.getPublishIconY(d))
        .attr("width", 20)
        .attr("height", 24)
        .attr("xlink:href", "../graph/share.svg")
        .on("click", (evt, d) => {
          this.selectedForm = this.mappedNode[d.index].form;
          this.selectedTab = 1;
          (this.$refs["panel"] as Element).classList.add("panel-active");
        });

      const communicationBox = nodeg
        .filter((d) => [4, 6, 7, 8].includes(d.type))
        .append("g")
        .attr("class", "communicationicon");

      communicationBox
        .append("svg:image")
        .attr("x", (d) => this.getCommunicationIconX(d))
        .attr("y", (d) => this.getCommunicationIconY(d))
        .attr("width", 20)
        .attr("height", 24)
        .attr("xlink:href", "../graph/communication.svg")
        .on("click", (evt, d) => {
          this.selectedForm = this.mappedNode[d.index].form;
          this.selectedTab = 3;
          (this.$refs["panel"] as Element).classList.add("panel-active");
        });

      nodeg
        .append("text")
        .attr("x", (d) => this.getTitlePosition(d))
        .attr("y", "0.31em")
        .text((d) => d.name)
        .attr("fill", "black")
        .attr("stroke-width", 3)
        .on("click", (evt, d) => {
          this.selectedForm = this.mappedNode[d.index].form;
          // eslint-disable-next-line no-console
          this.selectedTab = 0;
          (this.$refs["panel"] as Element).classList.add("panel-active");
        });

      nodeg
        .attr("class", (d) => (d.isRoot ? "node nodeRoot" : d.isTerminal ? "node nodeTerminal" : "node"))
        .on("mouseenter", (evt, d) => {
          this.activeNode = evt.srcElement;
          // let popupDev = d3.select(evt.srcElement).append("g").attr("class", "popupdev");
          // popupDev.append("rect").attr("width", 100).attr("height", 10);
          (this.groupNodes as any)
            .append("rect")
            .attr("class", "overlay-rect")
            .attr("x", -(window.innerWidth / 2))
            .attr("y", -(window.innerHeight / 2));
          (this.groupNodes as any).append(() => this.activeNode);
          (this.groupLinks as any)
            .selectAll("path")
            .attr("display", "none")
            .filter((l) => l.source.id === d.id || l.target.id === d.id)
            .attr("display", "block");

          // let coordinate = evt.srcElement.getBoundingClientRect();
          // (this.$refs["popup"] as Element).setAttribute("style", "display: block");
          // let popupWidth = (this.$refs["popup"] as Element).getBoundingClientRect();
          // (this.$refs["popup"] as Element).setAttribute(
          //   "style",
          //   "top: " +
          //     (coordinate.y - coordinate.height - 40) +
          //     "px; left: " +
          //     (coordinate.x + (coordinate.width / 2 - popupWidth.width / 2)) +
          //     "px; display: block"
          // );
        })
        .on("mouseleave", (evt) => {
          // d3.select(evt.srcElement).selectAll(".popupdev").remove();
          // (this.$refs["popup"] as Element).setAttribute("style", "display: none");
          (this.groupNodes as any).selectAll(".overlay-rect").remove();
          this.activeNode = null;
          (this.groupLinks as any).selectAll("path").attr("display", "block");
        });

      let update_links = (this.groupLinks as any).selectAll("path").data(this.linkList);
      // (this.groupLinks as any).selectAll("path").data(this.nodesList).exit().remove();
      update_links.exit().remove();
      let link = update_links
        .enter()
        .append("path")
        .attr("stroke", (d) => (this.color as any)(d.type))
        .attr("d", "M0,-5L10,0L0,5")
        .attr("orient", "auto-start-reverse")
        .attr("marker-end", "url(#triangle)");

      link.merge(update_links);

      (this.simulation as any).on("tick", () => {
        // this.update_links.selectAll("path").attr("d", this.linkArc);
        // this.update_nodes.selectAll("g").attr("transform", (d) => `translate(${d.x},${d.y})`);
        // (this.groupLinks as any).selectAll("path").attr("d", this.linkArc);
        // (this.groupNodes as any).selectAll("g").attr("transform", (d) => `translate(${d.x},${d.y})`);
        link.attr("d", this.linkArc);
        nodeg.attr("transform", (d) => `translate(${d.x},${d.y})`);
      });
      this.zoom = d3
        .zoom()
        .scaleExtent([1 / 2, 64])
        .on("zoom", (e) => this.handleZoom(e, this.groupNodes, this.groupLinks));
      (this.svg as any).call(this.zoom);
      (this.svg as any).transition().call((this.zoom as any).translateTo, 0, 0);
      setTimeout(() => {
        this.panToSelected();
      }, 100);
    },
    getSessionIconX(node) {
      return node.type == 2 ? -9 : -22;
    },
    getPublishIconX(node) {
      if (node.type == 3) {
        return -9;
      } else if (node.type == 7) {
        return -22;
      } else {
        return 1;
      }
    },
    getCommunicationIconX(node) {
      if ([6, 7].includes(node.type)) {
        return 1;
      } else {
        return -9;
      }
    },
    getSessionIconY(node) {
      return [4, 6, 7, 8].includes(node.type) ? -4 : -12;
    },
    getPublishIconY(node) {
      if ([4, 6, 8].includes(node.type)) {
        return -4;
      } else {
        return -12;
      }
    },
    getCommunicationIconY(node) {
      if ([4, 7].includes(node.type)) {
        return -12;
      } else {
        return -26;
      }
    },
    async loadAllForms(successCallBack?) {
      this.loading = true;
      try {
        this.$emit("change", async () => {
          await this.$nextTick();
          this.createtree(this.forms);
          if (successCallBack) {
            successCallBack();
          }
        });
      } catch (error: any) {
        console.error(error);
        this.$notification.showError(error.message);
        this.loading = false;
      }
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    userId(): string {
      return store.getters.userId;
    },
  },
});
