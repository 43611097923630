
import communicationService from "@/services/communicationService";
import formService from "@/services/formService";
import store from "@/store";
import Vue from "vue";
import { debounce } from "../../../../shared/extensions/tlence";
import { CommunicationSchedule, Form } from "../../../../shared/models";

export default Vue.extend({
  props: {
    dateRange: {
      type: Array as () => string[],
      required: true,
      default: () => [],
    },
    recipient: {
      type: String,
      required: true,
      default: "all",
    },
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Recipient",
          align: "start",
          sortable: true,
          value: "recipient",
        },
        { text: "Date", value: "date" },
        { text: "Form", value: "formName" },
        { text: "Action", value: "action", sortable: false },
      ],
      communications: [] as { recipient: string; date: string; formName: string }[],
      loading: false,
      showPreview: false,
      previewHtml: "",
      rendering: false,
      selectedSession: "",
    };
  },
  watch: {
    dateRange() {
      this.debouncedLoadData();
    },
    recipient() {
      this.debouncedLoadData();
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        const data = await communicationService.getUpcommingCommunications(this.companyId, this.form.id, this.dateRange, this.recipient);
        const communications = [] as { recipient: string; date: string; formName: string }[];
        for (const doc of data.docs) {
          const x = doc.data() as CommunicationSchedule;
          const form = x.formId ? ((await formService.getById(x.formId)).data() as Form) : null;
          communications.push({
            ...x,
            recipient: x.recipient.toString(),
            date: x.scheduleDateTime.toDate().toString(),
            formName: form ? form.name : "",
          });
        }
        this.communications = communications;
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async loadPreview(item: CommunicationSchedule) {
      try {
        this.selectedSession = item.sessionId;
        this.showPreview = true;
        this.rendering = true;
        if (item.formId) {
          const form = (await formService.getById(item.formId)).data() as Form;
          const renderResponse = await formService.renderForPreview(form.key, null, null, null);
          this.previewHtml = renderResponse.html;
        } else {
          this.previewHtml = "";
        }
      } catch (error: any) {
        this.previewHtml = "An error occurred while rendering preview.";
      } finally {
        this.rendering = false;
      }
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    debouncedLoadData(): (...args: any[]) => void {
      return debounce(this.loadData, 500);
    },
  },
});
