<template>
  <div>
    <div v-if="editor">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleBold().run()"
            :disabled="!editor.can().chain().focus().toggleBold().run() || disabled"
            :class="{ 'is-active': editor.isActive('bold'), 'editor-btn': true }"
          >
            <span class="mdi mdi-format-bold"></span>
          </v-btn>
        </template>
        <span>Bold</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleItalic().run()"
            :disabled="!editor.can().chain().focus().toggleItalic().run() || disabled"
            :class="{ 'is-active': editor.isActive('italic'), 'editor-btn': true }"
          >
            <span class="mdi mdi-format-italic"></span>
          </v-btn>
        </template>
        <span>Italic</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleStrike().run()"
            :disabled="!editor.can().chain().focus().toggleStrike().run() || disabled"
            :class="{ 'is-active': editor.isActive('strike'), 'editor-btn': true }"
          >
            <span class="mdi mdi-format-strikethrough"></span>
          </v-btn>
        </template>
        <span>Strike</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleCode().run()"
            :disabled="!editor.can().chain().focus().toggleCode().run() || disabled"
            :class="{ 'is-active': editor.isActive('code'), 'editor-btn': true }"
          >
            <span class="mdi mdi-code-not-equal-variant"></span>
          </v-btn>
        </template>
        <span>Code</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :disabled="disabled" @click="editor.chain().focus().unsetAllMarks().run()" class="editor-btn">
            <span class="mdi mdi-format-clear"></span>
          </v-btn>
        </template>
        <span>Clear</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().setParagraph().run()"
            :class="{ 'is-active': editor.isActive('paragraph'), 'editor-btn': true }"
            :disabled="disabled"
          >
            <span class="mdi mdi-format-paragraph"></span>
          </v-btn>
        </template>
        <span>Paragraph</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }), 'editor-btn': true }"
            :disabled="disabled"
          >
            h1
          </v-btn>
        </template>
        <span>H1</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }), 'editor-btn': true }"
            :disabled="disabled"
          >
            h2
          </v-btn>
        </template>
        <span>H2</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }), 'editor-btn': true }"
            :disabled="disabled"
          >
            h3
          </v-btn>
        </template>
        <span>H3</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 4 }), 'editor-btn': true }"
            :disabled="disabled"
          >
            h4
          </v-btn>
        </template>
        <span>H4</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 5 }), 'editor-btn': true }"
            :disabled="disabled"
          >
            h5
          </v-btn>
        </template>
        <span>H5</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
            :class="{ 'is-active': editor.isActive('heading', { level: 6 }), 'editor-btn': true }"
            :disabled="disabled"
          >
            h6
          </v-btn>
        </template>
        <span>H6</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': editor.isActive('bulletList'), 'editor-btn': true }"
            :disabled="disabled"
          >
            <span class="mdi mdi-format-list-bulleted"></span>
          </v-btn>
        </template>
        <span>Bullet List</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleOrderedList().run()"
            :class="{ 'is-active': editor.isActive('orderedList'), 'editor-btn': true }"
            :disabled="disabled"
          >
            <span class="mdi mdi-format-list-numbered"></span>
          </v-btn>
        </template>
        <span>Ordered List</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().toggleCodeBlock().run()"
            :class="{ 'is-active': editor.isActive('codeBlock'), 'editor-btn': true }"
            :disabled="disabled"
          >
            <span class="mdi mdi-code-braces"></span>
          </v-btn>
        </template>
        <span>Code Block</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" :disabled="disabled" @click="editor.chain().focus().setHorizontalRule().run()" class="editor-btn">
            <span class="mdi mdi-minus"></span>
          </v-btn>
        </template>
        <span>Horizontal Line</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"
            @click="setHyperLink"
            :class="{ 'is-active': editor.isActive('link'), 'editor-btn': true }"
          >
            <span class="mdi mdi-link"></span>
          </v-btn>
        </template>
        <span>Set Hyper Link</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().unsetLink().run()"
            :disabled="!editor.isActive('link') || disabled"
            class="editor-btn"
          >
            <span class="mdi mdi-link-off"></span>
          </v-btn>
        </template>
        <span>Remove Hyper Link</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().undo().run()"
            :disabled="!editor.can().chain().focus().undo().run() || disabled"
            class="editor-btn"
          >
            <span class="mdi mdi-undo"></span>
          </v-btn>
        </template>
        <span>Undo</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="editor.chain().focus().redo().run()"
            :disabled="!editor.can().chain().focus().redo().run() || disabled"
            class="editor-btn"
          >
            <span class="mdi mdi-redo"></span>
          </v-btn>
        </template>
        <span>Redo</span>
      </v-tooltip>
    </div>
    <v-card
      :loading="loading"
      :disabled="disabled"
      @click.capture.passive="focusAndEdit"
      @blur="editMode = false"
      class="mb-4"
      :class="{ 'field-box-deactive': !editMode }"
      :dark="!editMode"
      :raised="false"
      :ripple="false"
    >
      <v-card-title class="card-title">
        <v-spacer />
        <v-tooltip :disabled="disabled" bottom v-if="showClear && $vuetify.breakpoint.smAndUp">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="disabled" v-bind="attrs" v-on="on" color="primary" @click.stop="clearText" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Clear Text</span>
        </v-tooltip>
        <v-tooltip :disabled="disabled" bottom v-if="showEdit && $vuetify.breakpoint.smAndUp">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :disabled="disabled" v-bind="attrs" v-on="on" color="primary" @click.stop="focusAndEdit" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text class="pt-4">
        <div class="editor">
          <BubbleMenu
            :editor="editor"
            :tippy-options="{ placement: 'top-start' }"
            :shouldShow="(props) => props.editor.isActive('paragraph')"
          >
            <div class="menububble" :class="{ 'is-active': editMode }">
              <v-btn
                icon
                small
                title="Insert Form Data"
                class="menububble__button"
                :class="{ 'is-active': false }"
                @click="insertEmptyMention"
              >
                <v-icon color="#fff">mdi-form-textbox</v-icon>
              </v-btn>
            </div>
          </BubbleMenu>
          <EditorContent :id="id" class="editor__content" :editor="editor" />
        </div>
        <div class="suggestion-list" v-show="showSuggestions" ref="suggestions">
          <template v-if="hasResults">
            <v-card class="mx-auto" max-width="300" tile>
              <v-list dense>
                <v-list-item-group v-model="selectedItem" color="primary">
                  <template v-for="(item, index) in filteredItems">
                    <v-subheader v-if="item.isGroup" :key="index">{{ item.value }}</v-subheader>
                    <v-list-item v-else dense :key="item.key" :value="item.key" @click="selectItem(item)">
                      <v-list-item-icon v-if="item.icon">
                        <v-icon small v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.value"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </template>
          <div v-else>
            <v-card class="mx-auto" max-width="300" tile>
              <v-list dense>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title v-text="'No elements found.'"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
        </div>
        <div class="host-data-list" v-show="showHostDataSuggestions" ref="hostData">
          <template v-if="hasHostDataResults">
            <v-card class="mx-auto" max-width="300" tile>
              <v-list dense>
                <v-list-item-group v-model="selectedHostDataItem" color="primary">
                  <template v-for="item in hostDataFilteredItems">
                    <v-list-item dense :key="item.key" :value="item.key" @click="selectHostDataItem(item)">
                      <v-list-item-content>
                        <v-list-item-title v-text="item"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </template>
          <div v-else>
            <v-card class="mx-auto" max-width="300" tile>
              <v-list dense>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title v-text="'No elements found.'"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import tippy, { sticky } from "tippy.js";
// import { Editor, EditorContent, EditorMenuBubble } from "tiptap";
// import { HardBreak, Heading, Code, Bold, Italic, Placeholder } from "tiptap-extensions";
// import Mention from "@/plugins/tiptap-plugins/mention";
import Mention from "@tiptap/extension-mention";
import Link from "@tiptap/extension-link";
import { BubbleMenu, Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import { PluginKey } from "prosemirror-state";

export default {
  components: {
    EditorContent,
    BubbleMenu,
  },
  props: {
    suggestions: {
      type: Array,
      default: () => [],
    },
    formHostData: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      keepInBounds: true,
      editMode: false,
      query: null,
      hostDataQuery: null,
      suggestionRange: null,
      hostDataSuggestionRange: null,
      filteredItems: [],
      hostDataFilteredItems: [],
      selectedItem: null,
      selectedHostDataItem: null,
      insertMention: null,
      insertHostDataMention: null,
      editor: new Editor({
        editable: false,
        extensions: [
          StarterKit,
          Link.configure({
            openOnClick: false,
          }),
          Mention.extend({
            name: "mention",
          }).configure({
            suggestion: {
              items: (props) => {
                return this.suggestions.filter((x) => {
                  return x.isGroup || (x.title || "").toLowerCase().indexOf((props.query || "").toLowerCase()) >= 0;
                });
              },
              char: "{",
              allowSpaces: false,
              startOfLine: false,
              pluginKey: new PluginKey("mention"),
              decorationClass: "mention",
              render: () => {
                return {
                  onStart: (props) => {
                    this.query = true;
                    this.filteredItems = props.items;
                    this.suggestionRange = props.range;
                    this.renderPopup(props.decorationNode);
                    this.insertMention = props.command;
                  },
                  onUpdate: (props) => {
                    this.query = true;
                    this.filteredItems = props.items;
                    this.suggestionRange = props.range;
                    this.selectedItem = null;
                    this.renderPopup(props.decorationNode);
                  },
                  onExit: () => {
                    this.query = null;
                    this.filteredItems = [];
                    this.suggestionRange = null;
                    this.selectedItem = null;
                  },
                  onKeyDown: (propes) => {
                    if (propes.event.key === "ArrowUp") {
                      this.upHandler();
                      return true;
                    }
                    if (propes.event.key === "ArrowDown") {
                      this.downHandler();
                      return true;
                    }
                    if (propes.event.key === "Enter") {
                      this.enterHandler();
                      return true;
                    }
                    return false;
                  },
                };
              },
            },
          }),
          Mention.extend({
            name: "hostData",
          }).configure({
            suggestion: {
              items: (props) => {
                return this.formHostData.filter((x) => {
                  return (x || "").toLowerCase().indexOf((props.query || "").toLowerCase()) >= 0;
                });
              },
              char: "[",
              allowSpaces: false,
              startOfLine: false,
              pluginKey: new PluginKey("hostData"),
              decorationClass: "hostData",
              render: () => {
                return {
                  onStart: (props) => {
                    this.hostDataQuery = true;
                    this.hostDataFilteredItems = props.items;
                    this.hostDataSuggestionRange = props.range;
                    this.renderHostDataPopup(props.decorationNode);
                    this.insertHostDataMention = props.command;
                  },
                  onUpdate: (props) => {
                    this.hostDataQuery = true;
                    this.hostDataFilteredItems = props.items;
                    this.hostDataSuggestionRange = props.range;
                    this.selectedHostDataItem = null;
                    this.renderHostDataPopup(props.decorationNode);
                  },
                  onExit: () => {
                    this.hostDataQuery = null;
                    this.hostDataFilteredItems = [];
                    this.hostDataSuggestionRange = null;
                    this.selectedHostDataItem = null;
                  },
                  // onKeyDown: (propes) => {
                  //   if (propes.event.key === "ArrowUp") {
                  //     this.upHandler();
                  //     return true;
                  //   }
                  //   if (propes.event.key === "ArrowDown") {
                  //     this.downHandler();
                  //     return true;
                  //   }
                  //   if (propes.event.key === "Enter") {
                  //     this.enterHandler();
                  //     return true;
                  //   }
                  //   return false;
                  // },
                };
              },
            },
          }),
        ],
        content: this.value,
        onUpdate: (state) => {
          this.$emit("input", state.editor.getHTML());
        },
        onBlur: () => {
          this.editMode = false;
        },
      }),
    };
  },
  watch: {
    editMode(editMode) {
      this.editor.setOptions({
        editable: editMode,
      });
    },
    value(value) {
      this.editor.setOptions({
        content: value,
      });
    },
  },
  computed: {
    hasResults() {
      return this.filteredItems.length;
    },
    hasHostDataResults() {
      return this.hostDataFilteredItems.length;
    },
    showSuggestions() {
      return this.query || this.hasResults;
    },
    showHostDataSuggestions() {
      return this.hostDataQuery || this.hasHostDataResults;
    },
    notGroupSuggestions() {
      return (this.filteredItems || []).filter((x) => !x.isGroup);
    },
  },
  methods: {
    showBubbleMenu(props) {
      return props.isActive("paragraph");
    },
    async focusAndEdit() {
      this.editMode = true;
      await this.$nextTick();
      this.editor.chain().focus();
    },
    clearText() {
      this.editor.clearContent();
      this.$emit("input", "");
    },
    insertEmptyMention() {
      this.editor.commands.insertContent("{");
    },
    setHyperLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
    },
    upHandler() {
      if (!this.selectedItem) {
        this.selectedItem = this.notGroupSuggestions.length ? this.notGroupSuggestions[this.notGroupSuggestions.length - 1].key : null;
        return;
      }
      let index = this.notGroupSuggestions.findIndex((x) => x.key == this.selectedItem);
      if (index < 0 || index >= this.notGroupSuggestions.length) {
        this.selectedItem = this.notGroupSuggestions.length ? this.notGroupSuggestions[0].key : null;
        return;
      }
      index = index <= 0 ? this.notGroupSuggestions.length - 1 : index - 1;
      if (index < 0) {
        index = this.notGroupSuggestions.length - 1;
      }
      this.selectedItem = this.notGroupSuggestions[index].key;
    },
    downHandler() {
      if (!this.selectedItem) {
        this.selectedItem = this.notGroupSuggestions.length ? this.notGroupSuggestions[0].key : null;
        return;
      }
      let index = this.notGroupSuggestions.findIndex((x) => x.key == this.selectedItem);
      if (index < 0 || index >= this.notGroupSuggestions.length) {
        this.selectedItem = this.notGroupSuggestions.length ? this.notGroupSuggestions[0].key : null;
        return;
      }
      index = index >= this.notGroupSuggestions.length - 1 ? 0 : index + 1;
      if (index >= this.notGroupSuggestions.length) {
        index = 0;
      }
      this.selectedItem = this.notGroupSuggestions[index].key;
    },
    enterHandler() {
      const item = this.filteredItems.find((x) => x.key == this.selectedItem);
      if (item) {
        this.selectItem(item);
      }
    },
    selectItem(item) {
      if (this.insertMention) {
        this.insertMention({
          id: item.key,
          label: ` ${item.title} } `,
        });
      }
      this.editor.chain().focus();
    },
    selectHostDataItem(item) {
      if (this.insertHostDataMention) {
        this.insertHostDataMention({
          id: item,
          label: ` ${item} ] `,
        });
      }
      this.editor.chain().focus();
    },
    renderPopup(node) {
      const boundingClientRect = node.getBoundingClientRect();
      const { x, y } = boundingClientRect;
      if (x === 0 && y === 0) {
        return;
      }
      if (this.popup) {
        return;
      }
      this.popup = tippy(".suggestion-list", {
        getReferenceClientRect: () => boundingClientRect,
        appendTo: () => document.body,
        interactive: true,
        sticky: true,
        plugins: [sticky],
        content: this.$refs.suggestions,
        trigger: "manual",
        showOnCreate: true,
        placement: "bottom-end",
        inertia: true,
        duration: [400, 200],
        hideOnClick: false,
      });
    },
    renderHostDataPopup(node) {
      const boundingClientRect = node.getBoundingClientRect();
      const { x, y } = boundingClientRect;
      if (x === 0 && y === 0) {
        return;
      }
      if (this.hostDatapopup) {
        return;
      }
      this.hostDatapopup = tippy(".host-data-list", {
        getReferenceClientRect: () => boundingClientRect,
        appendTo: () => document.body,
        interactive: true,
        sticky: true,
        plugins: [sticky],
        content: this.$refs.hostData,
        trigger: "manual",
        showOnCreate: true,
        placement: "bottom-end",
        inertia: true,
        duration: [400, 200],
        hideOnClick: false,
      });
    },
    destroyPopup() {
      if (this.popup) {
        if (this.popup.length) {
          this.popup[0].destroy();
        }
        this.popup = null;
      }
    },
    destroyHostDataPopup() {
      if (this.hostDatapopup) {
        if (this.hostDatapopup.length) {
          this.hostDatapopup[0].destroy();
        }
        this.hostDatapopup = null;
      }
    },
  },
  beforeDestroy() {
    this.destroyPopup();
    this.destroyHostDataPopup();
  },
};
</script>

<style lang="scss">
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;

.mention {
  background: rgba($color-black, 0.1);
  color: rgba($color-black, 0.6);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

[data-type="mention"] {
  background: rgba($color-black, 0.1);
  color: rgba($color-black, 0.6);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

.hostData {
  background: rgba($color-black, 0.1);
  color: rgba($color-black, 0.6);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

[data-type="hostData"] {
  background: rgba($color-black, 0.1);
  color: rgba($color-black, 0.6);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

.mention-suggestion {
  color: rgba($color-black, 0.6);
}
.ProseMirror:focus {
  outline: none;
}

.editor p.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}
</style>

<style scoped lang="scss">
.editor {
  &__content {
    color: rgba(0, 0, 0, 0.87);
    overflow: auto;
  }
}
.suggestion-list {
  font-family: Roboto, sans-serif;
}

.host-data-list {
  font-family: Roboto, sans-serif;
}

.field-box {
  transition: background-color 0.5s ease;
  height: 100px;
  &-deactive {
    background: rgb(190, 188, 188);
    cursor: pointer;
  }
}
.menububble {
  background: #000;
  border-radius: 5px;
  padding: 0.3rem;
  &__button {
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;
  }
}

.card-title {
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
}

.editor-btn {
  background-color: #232323 !important;
  color: #fff;
  padding: 0 5 !important;
  margin: 2px;
  min-width: unset !important;
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
