import SessionService from "./SessionService";
import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
import { Communication } from "../models";

export default class CommunicationService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore, sessionService?: SessionService) {
    this.db = db;
  }

  public async getCommunication(communicationId: string) {
    return await this.db.collection("Communications").doc(communicationId).get();
  }

  public async saveCommunication(communication: Communication) {
    const communicationDoc = this.db.doc("/Communications/" + communication.id);
    await this.db.runTransaction(async (transaction) => {
      transaction.set(communicationDoc, communication, { merge: true });
    });
  }

  public async deleteCommunication(communication: Communication) {
    const communicationDoc = this.db.doc("/Communications/" + communication.id);
    await this.db.runTransaction(async (transaction) => {
      transaction.delete(communicationDoc);
    });
  }

  public async getAll(companyId: string) {
    return await this.db.collection("Communications").where("companyId", "==", companyId).orderBy("order").get();
  }

  public async getCommunications(formId: string, companyId: string) {
    return await this.db
      .collection("Communications")
      .where("companyId", "==", companyId)
      .where("formId", "==", formId)
      .orderBy("order")
      .get();
  }

  public async getFormCommunications(formId: string, companyId: string) {
    const childrenData = await this.getCommunications(formId, companyId);
    const communications = [] as Communication[];
    childrenData.forEach((doc) => {
      const f = doc.data() as Communication;
      if (f.companyId && f.companyId == companyId) {
        f.id = doc.id;
        communications.push(f);
      }
    });
    return communications;
  }

  public async getCommunicationScheduleBySession(sessionId: string, communicationId: string, companyId: string) {
    return await this.db
      .collection("CommunicationSchedules")
      .where("sessionId", "==", sessionId)
      .where("communicationId", "==", communicationId)
      .where("companyId", "==", companyId)
      .get();
  }

  public async getUpcommingCommunications(companyId: string, communicationFormId: string, dateRange: string[], recipient: string) {
    let x = this.db
      .collection("CommunicationSchedules")
      .where("companyId", "==", companyId)
      .where("communicationFormId", "==", communicationFormId)
      .where("scheduleDateTime", ">", firebase.firestore.Timestamp.now());
    if (dateRange[0]) {
      x = x.where("scheduleDateTime", ">=", firebase.firestore.Timestamp.fromDate(new Date(dateRange[0])));
    }
    if (dateRange[1]) {
      x = x.where("scheduleDateTime", "<=", firebase.firestore.Timestamp.fromDate(new Date(dateRange[1])));
    }
    if (recipient && recipient !== "all") {
      x = x.where("recipient", "==", recipient);
    }
    x = x.orderBy("scheduleDateTime", "asc");
    return await x.get();
  }

  public async getPastCommunications(companyId: string, communicationFormId: string, dateRange: string[], recipient: string) {
    let x = this.db
      .collection("CommunicationSchedules")
      .where("companyId", "==", companyId)
      .where("communicationFormId", "==", communicationFormId)
      .where("scheduleDateTime", "<", firebase.firestore.Timestamp.now());
    if (dateRange[0]) {
      x = x.where("scheduleDateTime", ">=", firebase.firestore.Timestamp.fromDate(new Date(dateRange[0])));
    }
    if (dateRange[1]) {
      x = x.where("scheduleDateTime", "<=", firebase.firestore.Timestamp.fromDate(new Date(dateRange[1])));
    }
    if (recipient && recipient !== "all") {
      x = x.where("recipient", "==", recipient);
    }
    x = x.orderBy("scheduleDateTime", "asc");
    return await x.get();
  }
}
