
import Vue from "vue";

export default Vue.extend({
  inheritAttrs: false,
  props: {
    showOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    showRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    solo: {
      type: Boolean,
      required: false,
      default: true,
    },
    itemText: {
      type: String,
      required: false,
      default: "text",
    },
    itemValue: {
      type: String,
      required: false,
      default: "value",
    },
    fieldBox: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
});
