import BasePublishService from "../../../shared/services/PublishService";
import { db } from "../../../shared/plugins/firebase-app";
import { Publish } from "../../../shared/models";
import firebase from "../../../shared/plugins/firebase-app";
import formService from "./formService";

class PublishService extends BasePublishService {
  constructor() {
    super(db);
  }

  public async savePublish(publish: Publish) {
    await db.collection("Publishes").doc(publish.id).set(publish, { merge: true });
    const existingLinkAliases = await db
      .collection("LinkAliases")
      .where("companyId", "==", publish.companyId)
      .where("formId", "==", publish.formId)
      .get();
    const linkAliasDocs: Array<{ linkId: string; doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> }> = [];
    const publishes = (
      await db.collection("Publishes").where("companyId", "==", publish.companyId).where("formId", "==", publish.formId).get()
    ).docs;
    for (const x of publishes) {
      const link = x.data() as Publish;
      if (link.linkAlias && link.linkAliasTag) {
        linkAliasDocs.push({ linkId: link.id, doc: db.collection("LinkAliases").doc(`${publish.companyId}.${link.linkAliasTag}`) });
      }
    }
    const formDoc = (await formService.getDocById(publish.formId)) as firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    await db.runTransaction(async (transaction) => {
      existingLinkAliases.forEach((x) => {
        transaction.delete(x.ref);
      });
      transaction.set(
        formDoc,
        { publishesCount: await formService.getFormPublishesCount(publish.companyId, publish.formId) },
        { merge: true }
      );
      for (const linkAliasDoc of linkAliasDocs) {
        transaction.set(linkAliasDoc.doc, {
          companyId: publish.companyId,
          formId: publish.formId,
          linkId: linkAliasDoc.linkId,
        });
      }
    });
  }
}

const publishService = new PublishService();
export default publishService;
