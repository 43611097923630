
import Vue from "vue";

export default Vue.extend({
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    exitEditModeOnEnter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
  watch: {
    editMode(value) {
      this.$emit("editModeChanged", value);
    },
  },
  methods: {
    handleExitEditModeOnEnter() {
      if (this.exitEditModeOnEnter) {
        this.editMode = false;
      }
    },
  },
});
