
import themeService from "@/services/themeService";
import IdGenerator from "../../../shared/extensions/IdGenerator";
import { defaultTheme } from "../../../shared/constants";
import Vue from "vue";
import { Form, Theme } from "../../../shared/models";
import ColorPicker from "@/components/ColorPicker.vue";
import formService from "@/services/formService";
import { debounce } from "../../../shared/extensions/tlence";
import AutoCompleteBox from "@/components/field-box/AutoCompleteBox.vue";
import emptyIcon from "@/assets/theme-empty.png";
import upIcon from "@/assets/theme-up.png";

export default Vue.extend({
  name: "ThemeSelector",
  components: {
    ColorPicker,
    AutoCompleteBox,
  },
  props: {
    themes: { type: Array as () => Theme[], required: true, default: () => [] },
    forms: { type: Array as () => Form[], required: true, default: () => [] },
    form: { type: Object as () => Form, required: false, default: null },
    formTheme: { type: String, required: false, default: null },
    label: { type: String, required: false, default: "Select a theme:" },
    disabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      emptyIcon,
      upIcon,
      defaultTheme: defaultTheme,
      selectorIsOpen: false,
      fontFamilies: ["Arial", "Tahoma", "Teko", "Cursive", "Fantasy", "Monospace"],
      fontSizes: [
        "xx-small",
        "x-small",
        "small",
        "medium",
        "large",
        "x-large",
        "xx-large",
        "xxx-large",
        "50%",
        "75%",
        "100%",
        "125%",
        "150%",
        "smaller",
        "larger",
      ],
      customeTheme: { ...defaultTheme },
      selectedTheme: this.formTheme as string | null,
      previewTheme: this.themes.find((x) => x.id == this.formTheme) || null,
      valid: true,
      nameRules: [(v) => !!v || "Name is required", (v) => (v && v.length >= 3) || "Name must have at least 3 letters"],
      saving: false,
      deleteDialog: false,
      selectedForDelete: null as string | null,
      deleting: false,
      activeThemeTab: 0,
      editMode: false,
      rendering: false,
      previewHtml: "",
      selectedForm: this.form ? this.form.key : ((this.forms.length > 0 ? this.forms[0].key : null) as string | null),
    };
  },
  watch: {
    activeThemeTab: function (val) {
      if (this.activeThemeTab == 0) {
        this.customeTheme = { ...defaultTheme };
        this.editMode = false;
        this.previewTheme = this.selectedTheme === null ? null : this.computedThemes.find((x) => x.id == this.selectedTheme) || null;
      } else if (this.activeThemeTab == 1) {
        this.previewTheme = this.customeTheme;
      }
    },
    selectorIsOpen: function (val) {
      if (!val) {
        this.previewTheme = this.selectedTheme === null ? null : this.computedThemes.find((x) => x.id == this.selectedTheme) || null;
      } else {
        this.activeThemeTab = 0;
        this.debouncedRender();
      }
    },
    formTheme(formTheme, oldValue) {
      if (formTheme) {
        this.selectedTheme = formTheme;
        this.previewTheme = formTheme === null ? null : this.computedThemes.find((x) => x.id == formTheme) || null;
      } else {
        this.selectedTheme = null;
        this.previewTheme = null;
      }
    },
    themes() {
      if (this.formTheme) {
        this.previewTheme = this.formTheme === null ? null : this.computedThemes.find((x) => x.id == this.formTheme) || null;
      } else {
        this.previewTheme = null;
      }
    },
    previewTheme: {
      handler(newValue, oldValue) {
        this.debouncedRender();
      },
      deep: true,
    },
    selectedForm(newValue, oldValue) {
      this.debouncedRender();
    },
  },
  methods: {
    editTheme(theme) {
      this.activeThemeTab = 1;
      this.editMode = true;
      this.customeTheme = { ...theme };
    },
    showDeleteDialog(id) {
      this.selectedForDelete = id;
      this.deleteDialog = true;
    },
    async deleteTheme() {
      try {
        this.deleting = true;
        if (!this.selectedForDelete) {
          return;
        }
        await themeService.deleteTheme(this.selectedForDelete);
        const t = this.themes.find((x) => x.id == this.selectedForDelete);
        if (t) {
          this.computedThemes.splice(this.themes.indexOf(t), 1);
        }
        if (this.selectedTheme == this.selectedForDelete) {
          this.selectedTheme = null;
          this.previewTheme = null;
        }
        this.selectedForDelete = null;
        this.deleteDialog = false;
        this.$notification.showSuccess("Theme deleted successfully");
      } catch (error: any) {
        this.selectedForDelete = null;
        this.deleteDialog = false;
        this.$notification.showError(error.message);
      } finally {
        this.deleting = false;
      }
    },
    toggle() {
      this.selectorIsOpen = !this.selectorIsOpen;
    },
    async addTheme() {
      if ((this.$refs.CustomThemeform as any).validate()) {
        try {
          this.saving = true;
          if (!this.editMode) {
            this.customeTheme.id = IdGenerator.newId();
            this.customeTheme.companyId = this.companyId;
          }
          await themeService.saveTheme(this.customeTheme);
          if (!this.editMode) {
            this.computedThemes.push(this.customeTheme);
          } else {
            if (this.customeTheme.id) {
              let index = this.themes.findIndex((x) => x.id == this.customeTheme.id);
              if (index >= 0) {
                this.computedThemes[index] = this.customeTheme;
                this.setSelected(this.computedThemes[index]);
              } else {
                throw new Error("Theme not found");
              }
            }
          }
        } catch (error: any) {
          this.$notification.showError(error.message);
        } finally {
          this.saving = false;
        }
        this.editMode = false;
        this.customeTheme = { ...defaultTheme };
        (this.$refs.CustomThemeform as any).resetValidation();
        this.activeThemeTab = 0;
      }
    },
    setSelected(theme) {
      this.previewTheme = theme?.id ? theme : null;
      this.selectedTheme = theme?.id;
      this.$emit("setSelected", this.selectedTheme);
    },
    async render() {
      try {
        if (!this.selectorIsOpen) {
          return;
        }
        this.rendering = true;
        let theme;
        if (this.previewTheme == null) {
          theme = null;
        } else {
          theme = { ...this.previewTheme };
        }
        if (this.selectedForm) {
          const renderResponse = await formService.renderForPreview(this.selectedForm, null, theme, null);
          this.previewHtml = renderResponse.html;
        } else {
          this.previewHtml = "";
        }
      } catch (error: any) {
        this.previewHtml = "An error occurred while rendering preview.";
      } finally {
        this.rendering = false;
      }
    },
    selectForm(form) {
      this.selectedForm = form;
    },
  },
  computed: {
    companyId(): string {
      return this.$store.getters.companyId;
    },
    userId(): string {
      return this.$store.getters.userId;
    },
    computedThemes(): Theme[] {
      return this.themes;
    },
    debouncedRender(): (...args: any[]) => void {
      return debounce(this.render, 500);
    },
  },
});
