
import Vue from "vue";
import { AppointmentElement } from "../../../../shared/models/Dto/AppointmentElement";
import LengthPicker from "../appointment/length-picker/LengthPicker.vue";
import IntervalPicker from "../appointment/interval-picker/IntervalPicker.vue";

export default Vue.extend({
  inheritAttrs: false,
  components: {
    LengthPicker,
    IntervalPicker,
  },
  props: {
    value: {
      type: Object as () => AppointmentElement,
      required: false,
    },
    exitEditModeOnEnter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
      appointment: this.value,
    };
  },
  mounted() {
    if (!this.appointment) {
      this.appointment = {
        duration: "15",
        intervals: [
          {
            times: [
              {
                from: "09:00",
                to: "17:00",
              },
            ],
            weekdays: [1],
          },
        ],
      };
    }
  },
  methods: {
    handleChange() {
      this.$emit("input", this.appointment);
    },
  },
});
