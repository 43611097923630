
import Vue from "vue";

function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
function rgbTextToHex(rgbText: string) {
  var numbers = rgbText.match(/\d+/g);
  if (numbers && numbers.length == 3) {
    return rgbToHex(parseInt(numbers[0]), parseInt(numbers[1]), parseInt(numbers[2]));
  }
  return "#fff";
}
function convertToHex(color: string) {
  return color && color.toLowerCase().startsWith("rgb") ? rgbTextToHex(color) : color;
}

export default Vue.extend({
  model: {
    prop: "color",
    event: "change",
  },
  props: {
    color: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      colorValue: convertToHex(this.color),
      menu: false,
    };
  },
  watch: {
    color(val: string) {
      this.colorValue = convertToHex(val);
    },
  },
  methods: {
    selectColor(color) {
      this.colorValue = color.hex || color;
      this.$emit("change", color.hex || color);
    },
  },
});
