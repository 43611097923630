
import Vue from "vue";
import { AppointmentIntervalElement } from "../../../../../shared/models/Dto/AppointmentIntervalElement";

export default Vue.extend({
  inheritAttrs: false,
  props: {
    value: {
      type: Array as () => AppointmentIntervalElement[],
      required: false,
    },
    exitEditModeOnEnter: {
      type: Boolean,
      required: false,
      default: true,
    },
    showClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
      weekdays: [
        { value: 1, text: "Monday" },
        { value: 2, text: "Tuesday" },
        { value: 3, text: "Wednesday" },
        { value: 4, text: "Thursday" },
        { value: 5, text: "Friday" },
        { value: 6, text: "Saturday" },
        { value: 7, text: "Sunday" },
      ],
      intervals: this.value,
    };
  },
  methods: {
    removeInterval(index) {
      this.intervals.splice(index, 1);
      this.handleChange();
    },
    AddNewInterval() {
      this.intervals.push({
        times: [
          {
            from: "09:00",
            to: "17:00",
          },
        ],
        weekdays: [1],
      });
      this.handleChange();
    },
    addTimeToInterval(index) {
      this.intervals[index].times.push({
        from: "09:00",
        to: "17:00",
      });
      this.handleChange();
    },
    removeTimeFromInterval(index, timeIndex) {
      this.intervals[index].times.splice(timeIndex, 1);
      this.handleChange();
    },
    handleChange() {
      this.$emit("input", this.intervals);
    },
  },
});
