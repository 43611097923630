
import communicationService from "@/services/communicationService";
import formService from "@/services/formService";
import store from "@/store";
import Session from "@/views/Session.vue";
import Vue from "vue";
import { CommunicationScheduleStatus } from "../../../../shared/enums/CommunicationScheduleStatus";
import { debounce } from "../../../../shared/extensions/tlence";
import { CommunicationSchedule, Form } from "../../../../shared/models";

export default Vue.extend({
  components: { Session },
  props: {
    dateRange: {
      type: Array as () => string[],
      required: true,
      default: () => [],
    },
    recipient: {
      type: String,
      required: true,
      default: "all",
    },
    form: {
      type: Object as () => Form,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Recipient",
          align: "start",
          sortable: true,
          value: "recipient",
        },
        { text: "Date", value: "date" },
        { text: "Form", value: "formName" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false },
      ],
      communications: [] as { recipient: string; date: string; formName: string; statusText: string; sessionId: string }[],
      loading: false,
      viewAudience: false,
      selectedSession: "",
    };
  },
  watch: {
    dateRange() {
      this.debouncedLoadData();
    },
    recipient() {
      this.debouncedLoadData();
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        const data = await communicationService.getPastCommunications(this.companyId, this.form.id, this.dateRange, this.recipient);
        const communications = [] as { recipient: string; date: string; formName: string; statusText: string; sessionId: string }[];
        for (const doc of data.docs) {
          const x = doc.data() as CommunicationSchedule;
          const form = x.formId ? ((await formService.getById(x.formId)).data() as Form) : null;
          communications.push({
            recipient: x.recipient,
            date: x.scheduleDateTime.toDate().toString(),
            formName: form?.name || "",
            statusText: x.status ? CommunicationScheduleStatus[x.status] : "",
            sessionId: x.sessionId,
          });
        }
        this.communications = communications;
      } catch (error: any) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    showSessionDetails(sessionId: string) {
      this.selectedSession = sessionId;
      this.viewAudience = true;
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
    debouncedLoadData(): (...args: any[]) => void {
      return debounce(this.loadData, 500);
    },
  },
});
